import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { ISettingsForm } from "../../types/types";
import { useAddSettingsMutation } from "../../services/API/settingsApiSlice";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EmergePaySubmitted } from "../../DmsTrigger";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API_GET_DEALER_SETTINGS } from "../../services/ApiConstants";
import OnBoarding from "../PerseusPay/OnBoarding/OnBoarding";
import React from "react";
import { EverestApi, TargetPayApi } from "../../utils/UtilTarget";
import { isEmpty } from "../../utils/Util";

const UNAUTHORIZED = 401;
const NOT_FOUND = 404;

const Settings = () => {
  const classes = useStyles();
  const location = useLocation();

  // const dealerId2 = useAppSelector((state: RootState) => state.dealer.dealerId);
  //const dealerId =  useAppSelector((state: RootState) => state.dealer.dealerId);

  const navigate = useNavigate();

  const { dealerId } = useParams();
  const dealerIdNum = parseInt(dealerId || "");
  const urlParams = new URLSearchParams(window.location.search);
  const access_token = urlParams.get("access_token");
  const login_source = urlParams.get("login_source");
  const [paymentGatewayId, SetPaymentGatewayId] = React.useState<any>(0);
  const [isDealerSetup, SetIsDealerSetup] = React.useState<any>(false);

  const [skipToken, setSkipToken] = useState<boolean>(true);
  const [settings, setSettings] = useState<ISettingsForm>({
    DealerId: null,
    Oid: "",
    AuthToken: "",
    SecretPhrase: "",
    AllowTextToPay: false,
    IsSandbox: false,
    AllowGiftCards: false,
    MerchantName: "",
    MerchantKey: "",
  });
  const [settingError, setSettingError] = useState<any>();
  const [data, setData] = useState<any>({});
  const [settingIsFetching, setSettingIsFetching] = useState(false);

  /*
   * API Calls
   */

  // Get Dealer PaymentGateway
  async function GetPaymentGateway(dealerId: any) {
    const res = await TargetPayApi(
      `GetDealerPaymentGateway?dealerId=${dealerId}`,
      null,
      "get"
    );

    if (!isEmpty(res)) {
      if (res == 1) {
        SetPaymentGatewayId(res);
        await getDealerSettings();
        return;
      } else if (res == 2) {
        SetPaymentGatewayId(res);
      } else if (res == 0) {
        navigate("/401");
        return;
      }
    }

    SetIsDealerSetup(true);
  }

  // Fetch Dealer Settings
  const getDealerSettings = async () => {
    try {
      setSettingIsFetching(true);
      const authToken = access_token
        ? access_token
        : localStorage.getItem("access_token");
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(
        `${baseUrl}${API_GET_DEALER_SETTINGS}/${dealerIdNum.toString()}`,
        {
          method: "GET",
          cache: "no-store",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (!response.ok) {
        setSettingError(response.status);
        if (response.status == 401) {
          toast.error("User Unauthorized", { toastId: 103 });
        }
      } else {
        const responseData = await response.json();
        setData(responseData);
      }
      setSettingIsFetching(false);
    } catch (error) {
      toast.error("Something went wrong");
      setSettingIsFetching(false);
    }
  };

  // Add Settings API
  const [
    addSettings,
    { isLoading, isError: addSettingsIsError, error: addSettingsError },
  ] = useAddSettingsMutation();

  /*
   * Effects
   */

  // Fetch Dealer Settings
  useEffect(() => {
    GetPaymentGateway(dealerIdNum);
  }, [dealerIdNum]);

  useEffect(() => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      DealerId: dealerIdNum,
    }));
  }, []);

  useEffect(() => {
    // Check if URL parameters are present, then set to localStorage
    if (access_token && login_source) {
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("Login_Source", login_source);
    }
  }, [access_token]);

  useEffect(() => {
    // Response error handling
    if (settingError == NOT_FOUND) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        DealerId: dealerIdNum,
        Oid: "",
        AuthToken: "",
        IsSandbox: false,
        AllowTextToPay: false,
        SecretPhrase: "",
        AllowGiftCards: false,
        MerchantName: "",
        MerchantKey: "",
      }));

      toast.error("Record not found", { toastId: 104 });
    } else {
      setSettings((prevSettings) => ({
        ...prevSettings,
        DealerId: dealerIdNum,
        Oid: data?.oid || "",
        AuthToken: data?.authToken || "",
        IsSandbox: data?.isSandbox || false,
        AllowTextToPay: data?.allowTextToPay || false,
        SecretPhrase: data?.secretPhrase || "",
        AllowGiftCards: data?.allowGiftCards || false,
        MerchantName: data?.merchantName || "",
        MerchantKey: data?.merchantKey || "",
      }));
    }
  }, [data, settingError]);

  /*
   * Handlers
   */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: checked,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Add Settings API Call
    await addSettings(settings)
      .then((response: any) => {
        toast.success("Settings saved successfully");

        // Login_Source = DMS_IDEAL
        if (localStorage.getItem("Login_Source")?.toString() === "DMS_IDEAL") {
          // Ideal DMS Trigger
          EmergePaySubmitted(settings);

          // fetch data after submit
          getDealerSettings();
        } else {
          // fetch data after submit
          getDealerSettings();
        }
      })
      .catch((error: any) => {
        toast.error("Something went wrong", { toastId: 105 });

        // reset form state
        setSettings((prevSettings) => ({
          ...prevSettings,
          Oid: "",
          AuthToken: "",
          IsSandbox: false,
          AllowTextToPay: false,
          SecretPhrase: "",
          AllowGiftCards: false,
          MerchantName: "",
          MerchantKey: "",
        }));
      });
  };

  return (
    <Box>
      {paymentGatewayId == 1 && (
        <Box>
          <>
            <Helmet>
              <title> Settings </title>
            </Helmet>

            {/* Display LinearProgress on data fetching*/}
            {settingIsFetching && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}

            <Grid container style={{ padding: "20px" }}>
              {/* Left Empty Grid */}
              <Grid item md={3} xs={12} />

              {/* Center Grid */}
              <Grid item md={6} xs={12}>
                <form onSubmit={handleSubmit}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      style={{ textAlign: "center", marginBottom: "30px" }}
                    >
                      EmergePay Settings
                    </Typography>
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: "20px" }}>
                    <TextField
                      fullWidth
                      label="Oid"
                      name="Oid"
                      required
                      value={settings.Oid}
                      onChange={handleChange}
                      size="small"
                      type="text"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: "20px" }}>
                    <TextField
                      fullWidth
                      label="Auth Token"
                      name="AuthToken"
                      required
                      value={settings.AuthToken}
                      onChange={handleChange}
                      size="small"
                      type="text"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: "20px" }}>
                    <TextField
                      fullWidth
                      label="Secret Phrase"
                      name="SecretPhrase"
                      required
                      value={settings.SecretPhrase}
                      onChange={handleChange}
                      size="small"
                      type="text"
                      variant="outlined"
                    />
                  </Grid>

                  {/* ------------------------------------------- */}

                  <Grid item xs={12} style={{ marginBottom: "5px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={settings.AllowTextToPay}
                          onChange={handleCheckBox}
                          name="AllowTextToPay"
                        />
                      }
                      label="Allow Text-To-Pay"
                    />
                  </Grid>

                  <hr />

                  <Grid item xs={12} style={{ marginBottom: "5px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={settings.AllowGiftCards}
                          onChange={handleCheckBox}
                          name="AllowGiftCards"
                        />
                      }
                      label="Allow Gift Cards"
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: "20px" }}>
                    <TextField
                      fullWidth
                      label="Merchant Name"
                      name="MerchantName"
                      value={settings.MerchantName}
                      onChange={handleChange}
                      size="small"
                      type="text"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: "20px" }}>
                    <TextField
                      fullWidth
                      label="Merchant Key"
                      name="MerchantKey"
                      value={settings.MerchantKey}
                      onChange={handleChange}
                      size="small"
                      type="text"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "Submitting..." : "Submit"}
                    </Button>
                  </Grid>
                </form>
              </Grid>

              {/* Right Empty Grid */}
              <Grid item md={3} xs={12} />
            </Grid>
          </>
        </Box>
      )}
      {paymentGatewayId == 2 && isDealerSetup == true && (
        <Box
          style={{
            marginTop:
              location.pathname.includes("dashboard") == true ? "0px" : "15px",
          }}
        >
          <OnBoarding />
        </Box>
      )}
    </Box>
  );
};

export default Settings;

const useStyles = makeStyles(() => ({
  mb: {
    marginBottom: "50px",
  },
}));
