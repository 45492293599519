import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import React from "react";
import { isEmpty } from "../../../utils/Util";
import { TargetPayApi } from "../../../utils/UtilTarget";
import { Link } from "react-router-dom";
import OnboardingErrors from "./OnboardingErrors";
import OnBoardingStatusDetails from "./OnBoardingStatusDetails";

export default function OnBoardingStatus() {
  const [IsLoading, SetIsLoading] = React.useState<any>(false);
  const [DealersDetail, SetDealersDetail] = React.useState<any>(null);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<any>(false);
  const [selectedRowId, setSelectedRowId] = React.useState<any>(null);
  const [isDetailPopupOpen, setOpenDetailsPopup] = React.useState<boolean>(false);
  const [selectedDealer, setSelectedDealer] = React.useState<any>(null);

  const toggleDrawer = (rowId: any) => {
    setIsDrawerOpen(!isDrawerOpen);
    setSelectedRowId(rowId);
  };

  const openDetailsPopup = (selDealer) => {
	setSelectedDealer(selDealer);
    setOpenDetailsPopup(true);
  };

  const handlePopupClose = (value: boolean) => {
	setOpenDetailsPopup(false);
  };

  useEffect(() => {
    document.title = "OnBoarding Status";
    GetDealersDetail();
  }, []);

  async function GetDealersDetail() {
    SetIsLoading(true);
    const res = await TargetPayApi("GetDealersDetail", null, "get");
    SetDealersDetail(res);

    SetIsLoading(false);
  }

  return (
    <Box style={{ border: "0px solid red" }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ padding: "0px", paddingRight: "0px" }}
      >
        {IsLoading == true && (
          <Box>
            Please wait. Processing.....<CircularProgress></CircularProgress>{" "}
          </Box>
        )}
      </Box>

      {IsLoading == false && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ padding: "0px", paddingRight: "0px" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ padding: "0px", paddingRight: "0px" }}
          >
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table
                size="small"
                sx={{ minWidth: 500 }}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ fontWeight: "bold" }}>
                      Name
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "bold" }}>
                      Status
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "bold" }}>
                      Setup Date
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "bold" }}>
                      Merchant
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "bold" }}>
                      Store
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "bold" }}>
                      Industry
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "bold" }}>
                      Pricing Model
                    </TableCell>
					<TableCell>&nbsp;</TableCell>					
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {!isEmpty(DealersDetail) ? (
                      <>
                        {DealersDetail.map((row: any, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {row.name} {" - "} {row.dmsDealerId}
                            </TableCell>
                            <TableCell align="left" sx={{ width: "270px" }}>
                              {row.status}
                              {row.status === "Onboarding In-progress" &&
                              !isEmpty(row.verificationProblems) ? (
                                <Link
                                  to="#"
                                  onClick={() => toggleDrawer(row.dealerId)}
                                >
                                  {" "}
                                  (View errors)
                                </Link>
                              ) : null}

                              {isDrawerOpen &&
                                selectedRowId === row.dealerId && (
                                  <OnboardingErrors
                                    open={isDrawerOpen}
                                    onClose={() => toggleDrawer(null)}
                                    verificationProblems={
                                      row.verificationProblems
                                    }
                                  />
                                )}
                            </TableCell>
                            <TableCell align="left" sx={{ width: "200px" }}>
                              {isEmpty(row.setupDate)
                                ? ""
                                : row.setupDate.toUSDateFormat()}
                            </TableCell>
                            <TableCell align="left">{row.merchantId}</TableCell>
                            <TableCell align="left">{row.storeName}</TableCell>
                            <TableCell align="left">
                              {row.industryCode} {" - "}
                              {row.industryDescription}
                            </TableCell>
                            <TableCell align="left">
                              {row.pricingModel}
                            </TableCell>
							<TableCell>
								<Link to="#" onClick={() => openDetailsPopup(row)}>
									more...
                                </Link>
							</TableCell>	
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell>
                          <Typography
                            color="primary"
                            sx={{ textAlign: "center" }}
                          >
                            No record found!
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
	    <OnBoardingStatusDetails 
			openPopup={isDetailPopupOpen}
			selectedDealer={selectedDealer}
			onClose={handlePopupClose}></OnBoardingStatusDetails>
    </Box>
	
  );
}
