export enum PaymentMethodType {
    Visa = "visa",
    Maestro = "maestro",
    MasterCard = "mc",
    AmericanExpress = "amex",
    Discover = "discover",
    Diners = "diners"
}

export enum PaymentStatus {
    Success = "Success",
    Failed = "Failed",
    Pending = "Pending",
	Refunded = "Refunded",
	Cancelled = "Cancelled",
    PendingRefund = "Pending Refund"
}

export enum CardType{
    CreditSale = "CreditSale",
    DebitSale = "DebitSale"
}