import { JSXElement } from "@babel/types";
import { Help, Info } from "@mui/icons-material";
import { IconButton, Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import { useEffect, useState } from "react";
import React from "react";

/** 
 * @param {String[]}      content    - mandatory - text to show inside tooltip 
 * @param {String}        placement  - optional  - where to show the tooltip like 'top-start', 'top', 'top-end'
 * @param {object}        styles     - optional  - styles that we want to apply on tooltip 
 * @param {JSXElement[]}  children   - optional  - element(s) which display tooltip while hovering it
 * @param {object}        iconStyles - optional  - styles that we want to apply on target icon (that will display tooltip)
 */
export default function HtmlTooltip(props){
   
    const isChildNull = (children) => {
        return Boolean(!children || children.length == 0);
      };

    const defaultStyle = {
        bgcolor: '#424242',
        maxWidth: 650
        // // color: 'common.black',
        // // '& .MuiTooltip-arrow': {
        // //   color: 'common.black',
        // // },
    };

    const defaultIconStyle = {
        fontSize: "22px"
    };

   return (
    <Tooltip title = {props.content}
         placement = {props.placement}
         componentsProps={{
            tooltip: {
                sx: (props.styles ? props.styles : defaultStyle),
            },
        }}>        
        { isChildNull(props.children)
            ? (<IconButton > 
                <Info style={(props.iconStyles ? props.iconStyles : defaultIconStyle)}></Info> </IconButton>)
            : props.children
        }
    </Tooltip>
   );
}

// // const MyButton = styled(Button)(
// //     ({ theme }) => ({
// //       backgroundColor: theme.palette.primary.light,
// //       color: theme.palette.grey[900],
// //       padding: theme.spacing(2),
// //       textAlign: 'center',
// //       ...theme.typography.h6,
// //     })
// //   );

// // export default MyButton;


// // // // const Basic = styled(Box)`
// // // //   background-color: aliceblue;
// // // //   color: darkslategray;
// // // //   padding: 2rem;
// // // //   text-align: center;
// // // // `;


// // const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
// //     <Tooltip {...props} classes={{ popper: className }} />
// //   ))(({ theme }) => ({
// //     [`& .${tooltipClasses.tooltip}`]: {
// //       backgroundColor: '#424242',
// //       //color: 'rgba(0, 0, 0, 0.87)',
// //       maxWidth: 650,
// //       fontSize: theme.typography.pxToRem(12),
// //       border: '1px solid grey',
// //     },
// //   }));