export const COLORS = {
    text: 'black',
    background: 'white',
    backgroundButton: 'rgb(25, 118, 210) !important',
    primary: 'rebeccapurple',
    
  };

export const FONTS = {
    default: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    labelSize:"11.53px !important",
    InputSize:"small !important"
};

