import moment from "moment";

declare global {
  interface String {
    toUSDateFormat(format: string): string;
  }
}

String.prototype.toUSDateFormat = function (format?: string) {
  if (!this) {
    return "";
  }

  let targetFormat = format ? format : "M/D/YYYY h:mm:ss A";
  let ret = moment.utc(this).local().format(targetFormat);

  if (!moment(ret, targetFormat, true).isValid()) {
    return "";
  }

  return ret;
};

export {};
