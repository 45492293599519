import jwt_decode from "jwt-decode";
import { Box } from "@mui/material";
import DashboardApp from "./DashboardApp";
import DashboardPerseusPay from "./DashboardPerseusPay";
import { setDealerId } from "../../features/dealer/dealerSlice";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  EverestApi,
  DealerProfile,
  TargetPayApi,
  GetDealerSettings,
} from "../../utils/UtilTarget";
import React from "react";
import { isEmpty } from "../../utils/Util";
import PreOnBoarding from "../PerseusPay/OnBoarding/PreOnBoarding";
import { Navigate, useNavigate } from "react-router-dom";
import OnBoarding from "../PerseusPay/OnBoarding/OnBoarding";

export default function DashboardMain() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const stateDealerId = useAppSelector(
    (state: RootState) => state.dealer.dealerId
  );
  const [paymentGatewayId, SetPaymentGatewayId] = React.useState<any>(-1);
  const [dealerId, setDealerIdLocal] = React.useState<any>(GetDealerId());
  const [tlDomainUser, SetTlDomainUser] = React.useState<any>("false");

  function GetDealerId() {
    return stateDealerId;
  }

  useEffect(() => {
    GetPaymentGateway();
  }, [dealerId]);

  async function GetPaymentGateway() {
    const isTLDomainUser = localStorage.getItem("isTLDomainUser");
    SetTlDomainUser(isTLDomainUser);
    if (isTLDomainUser === "true") return;

    let _dealerId = dealerId;
    _dealerId = await DealerProfile("dealerId");
    if (isEmpty(dealerId) || dealerId != _dealerId) {
      _dealerId = await DealerProfile("dealerId");
      if (isEmpty(_dealerId)) _dealerId = -1;
      setDealerIdLocal(_dealerId);
      dispatch(setDealerId(_dealerId));
      return;
    }

    // Get Dealer Settings
    await GetDealerSettings(_dealerId);

    // Get Dealer Payment Gateway
    let payment_gateway = localStorage.getItem("payment_gateway");

    if (isEmpty(payment_gateway)) {
      payment_gateway = await TargetPayApi(
        `GetDealerPaymentGateway?dealerId=${_dealerId}`,
        null,
        "get"
      );

      if (isEmpty(payment_gateway)) {
        navigate("/401");
        return;
      }
    }

    SetPaymentGatewayId(Number(payment_gateway));
    localStorage.setItem("payment_gateway", payment_gateway);
  }

  return (
    <Box>
      {paymentGatewayId === 0 && tlDomainUser === "false" && (
        <Navigate to="/dashboard/perseuspaysetting" />
      )}
      {paymentGatewayId === 1 && tlDomainUser === "false" && <DashboardApp />}
      {paymentGatewayId === 2 && tlDomainUser === "false" && (
        <DashboardPerseusPay DealerId={dealerId} />
      )}
      {tlDomainUser === "true" && <Navigate to="/dashboard/preonboarding" />}
    </Box>
  );
}
