import "../../utils/string.extension";
import DateRangePicker from "rsuite/DateRangePicker";
import {
  format,
  startOfDay,
  endOfDay,
  addDays,
  subDays,
  subMonths,
  startOfWeek,
  endOfWeek,
} from "date-fns";
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  EverestApi,
  TargetPayApi,
  DealerProfile,
} from "../../utils/UtilTarget";
import { isEmpty } from "../../utils/Util";
// import moment from "moment";
import axios from "axios";
import { useEffect, useState } from "react";
import * as React from "react";
import { alpha } from "@mui/material/styles";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import { visuallyHidden } from "@mui/utils";
import SearchIcon from "@mui/icons-material/Search";
import { Helmet } from "react-helmet-async";
import StoreConfig from "./StoreConfig";
import TransferTransactions from "./TransferTransactions";
import Report1 from "./Report1";
import { DateRangePickerProps } from "rsuite";
import { FormStyleCollection as formStyles } from "../../styles/form";
import { StyleCreator } from "../../helpers/stylecreator";
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TableHead,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Popover,
  Drawer,
  Link,
} from "@mui/material";
const Ranges: DateRangePickerProps["ranges"] = [
  {
    label: "Today",
    value: [startOfDay(new Date()), endOfDay(new Date())],
    placement: "left",
  },

  {
    label: "Yesterday",
    value: [
      startOfDay(addDays(new Date(), -1)),
      endOfDay(addDays(new Date(), -1)),
    ],
    placement: "left",
  },

  {
    label: "Last 7 Days",
    value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
    placement: "left",
  },

  {
    label: "This Week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: "left",
  },

  {
    label: "Next Week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7),
      ];
    },
    placement: "left",
  },
];

let dollarUSLocale = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  useGrouping: true,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}); // $148,000

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
}

const headCells = [
  {
    id: "InvoiceId",
    numeric: false,
    disablePadding: false,
    label: "Order#",
    canSort: false,
  },
  {
    id: "CustomerName",
    numeric: false,
    disablePadding: false,
    label: "Customer",
    canSort: false,
  },
  {
    id: "DateCreated",
    numeric: false,
    disablePadding: false,
    label: "Date Created",
    canSort: false,
  },
  {
    id: "RequestedAmount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
    canSort: false,
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    canSort: false,
  },
  {
    id: "",
    numeric: false,
    disablePadding: false,
    label: "Card",
    canSort: false,
  },
  {
    id: "",
    numeric: false,
    disablePadding: false,
    label: "Action",
    canSort: false,
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: "asc";
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index: any) => (
          <TableCell
            key={index}
            align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

let mySearchTimer: any;
let voidClicked = false;
let voidNowClicked = false;

// const Ranges: DateRangePickerProps["ranges"] = [
//     {
//       label: "Today",
//       value: [startOfDay(new Date()), endOfDay(new Date())],
//       placement: "left",
//     },

//     {
//       label: "Yesterday",
//       value: [
//         startOfDay(addDays(new Date(), -1)),
//         endOfDay(addDays(new Date(), -1)),
//       ],
//       placement: "left",
//     },

//     {
//       label: "Last 7 Days",
//       value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
//       placement: "left",
//     },

//     {
//       label: "This Week",
//       value: [startOfWeek(new Date()), endOfWeek(new Date())],
//       placement: "left",
//     },

//     {
//       label: "Next Week",
//       closeOverlay: false,
//       value: (value) => {
//         const [start = new Date()] = value || [];
//         return [
//           addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
//           addDays(endOfWeek(start, { weekStartsOn: 0 }), 7),
//         ];
//       },
//       placement: "left",
//     },
//   ];

export default function AdyenTransactions(props) {
  const classes = StyleCreator.makeStyles(formStyles.all);

  const voidButton = React.useRef<any>(null);
  const voidNowButton = React.useRef<any>(null);

  const [orderBy, setOrderBy] = React.useState<any>("");
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [paymentData, setPaymentData] = React.useState<any>([]);
  const [dealerLocations, setDealerLocations] = React.useState<any>([]);
  const [dealerLocation, setDealerLocation] = React.useState<any>(
    props.DealerId
  );
  const [filterType, setFilterType] = useState("customerName");
  const [search, setSearch] = useState(GetDefaultSearch());
  const [totalRows, setTotalRows] = React.useState(0);
  const [currentRow, setCurrentRow] = useState([] as any);

  const [showDetail, setShowDetail] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cardClickCount, SetCardClickCount] = React.useState(0);

  const [voidTransaction, setVoidTransaction] = React.useState(false);
  const [anchorVoid, setAnchorVoid] = React.useState(null);
  const [isVoiding, setIsVoiding] = React.useState(false);
  const [counter, setCounter] = React.useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openTag, setOpenTag] = React.useState("");
  const [adyenResponse, setAdyenResponse] = React.useState<any>(null);
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfDay(subMonths(new Date(), 2)),
    endOfDay(new Date()),
  ]);

  let usecomponent = Report1;
  let LastjsonString = "";

  useEffect(() => {
    if (search == null) return;
    clearTimeout(mySearchTimer);
    mySearchTimer = setTimeout(function () {
      GetPayments();
    }, 700);
  }, [search]);

  useEffect(() => {
    GetPayments();
  }, [page, rowsPerPage, dealerLocation, dateRange]);

  useEffect(() => {
    // alert(dealerLocation);
    // if(isEmpty(props.paymentData))
    if (isEmpty(props.paymentData)) {
      GetLocations();
      GetTransferPayments();
    }
  }, []);

  async function GetLocations() {
    // let dealerId= await DealerProfile("companyDealerId"); // props.paymentData.dealerId);

    let companyDealerId = await DealerProfile("companyDealerId");
    if (!isEmpty(props.paymentData))
      companyDealerId = props.paymentData.dealerId;

    let obj: any = new Object();
    let objBody: any = new Object();

    objBody.DealerId = companyDealerId;

    obj.query = "GetDealer";
    obj.queryBody = objBody;

    const response = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );
    setDealerLocations(response);
  }

  function GetDefaultSearch() {
    if (!isEmpty(props.paymentData)) {
      return props.paymentData.void
        ? props.paymentData.paymentId
        : props.paymentData.invoiceId;
    }
    return "";
  }

  async function GetTransferPayments() {
    let obj: any = new Object();
    let objBody: any = new Object();

    objBody.DealerId = 10038;
    objBody.UserId = 12;
    objBody.MachineName = "KarticLT";

    let url = `${process.env.REACT_APP_EVEREST_URL}api/Query`;
    try {
      obj.query = "PerseusPay.TransferTransactions";
      obj.queryBody = objBody;

      const response = await TargetPayApi(
        "query",
        JSON.stringify(obj),
        "post",
        "Everest"
      );
    } catch (err) {
      alert(err);
    }
  }

  async function GetPayments() {
    let obj: any = new Object();
    let objBody: any = new Object();

    if (isEmpty(props.paymentData)) {
      objBody.CompanyDealerId = await DealerProfile("companyDealerId");
      objBody.DealerId = dealerLocation;
    }
    if (!isEmpty(props.paymentData))
      objBody.DealerId = props.paymentData.dealerId;
    objBody.Tag = isEmpty(props.paymentData) ? "" : "makeapayment";
    objBody.Search = search;
    objBody.PageNumber = page;
    objBody.PageSize = rowsPerPage;

    objBody.StartDate = dateRange[0];
    objBody.EndDate = dateRange[1];

    let jsonString = JSON.stringify(objBody);

    if (LastjsonString == jsonString) return;
    LastjsonString = jsonString;

    obj.query = "PerseusPay.Payments";
    obj.queryBody = objBody;

    const response = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );

    setPaymentData(response);
    setTotalRows(page == 0 ? response.Count : totalRows);
  }

  function DisableVoidNow(): any {
    let val = isVoiding == true || props.terminal == 0;
    if (currentRow.CancelOrRefund == true) val = true;
    if (
      !val &&
      voidNowButton &&
      !voidNowClicked &&
      voidNowButton.current != null
    ) {
      // alert('k1');
      voidNowClicked = true;
      let timerId = setTimeout(async function ClickPayNow() {
        voidNowButton.current.click();
      }, 200);
    }
    return val;
  }

  async function VoidTransaction() {
    let obj: any = new Object();
    obj.POIID = props.terminal;
    obj.PaymentId = currentRow.PaymentId;
    obj.pspReference = currentRow.pspReference;
    let jsonString = JSON.stringify(obj);

    const res = await TargetPayApi("AdyenTerminalCancelPayment", jsonString);
    setAdyenResponse(res);

    if (res.result == "Reject") {
    } else {
      props.onVoidPayment(res);
      setVoidTransaction(false);
      currentRow.CancelOrRefund = 1;
      setCounter(counter + 1);
    }
  }

  useEffect(() => {
    document.title = "Payments";
  }, []);

  function ShowConfig() {
    setOpenDrawer(true);
  }

  function PaymentData() {
    if (paymentData == null || paymentData.Data == null) return [];
    return paymentData.Data;
  }

  function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Transactions
          </Typography>
        )}
      </Toolbar>
    );
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    // const isAsc = orderBy === property && order === 'asc';
    // setOrder(isAsc ? 'desc' : 'asc');
    // setOrderBy(property);
  };

  // const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     const newSelected = rows.map((n) => n.id);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  // 5
  //   // page > 0 ? Math.max(0, (1 + page) * rowsPerPage -  paymentData.length) : 0;

  // const visibleRows = React.useMemo(
  //   () =>
  //     stableSort(paymentData.Data , getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage,
  //     ),
  //   [order, orderBy, page, rowsPerPage],
  // );

  function emptyRows() {
    if (!isEmpty(props.paymentData)) return 0;
    if (paymentData == null || paymentData.Data == null) return 10;
    let rows = rowsPerPage - paymentData.Data.length;
    if (rows > 15 && paymentData.Data.length > 5) rows = 5;
    if (paymentData.Data.length > 10) return 0;
    return rows;
  }

  function PaymentType(row: any) {
    const myResult = JSON.parse(row.AdyenResponse);
    if (myResult == null) return "";
    if (myResult.notificationItems.length > 0) {
      if (myResult.notificationItems[0].NotificationRequestItem != null) {
        return myResult.notificationItems[0].NotificationRequestItem
          .paymentMethod;
      }
    }
    return "";
  }

  function CardInfo(row: any) {
    try {
      const myResult = JSON.parse(row.AdyenResponse);
      if (myResult == null) return "";
      let cardInfo = PaymentType(row);
      if (myResult.notificationItems.length > 0) {
        if (
          myResult.notificationItems[0].NotificationRequestItem
            .additionalData != undefined
        ) {
          cardInfo =
            cardInfo +
            " *****" +
            myResult.notificationItems[0].NotificationRequestItem.additionalData
              .cardSummary +
            " (" +
            myResult.notificationItems[0].NotificationRequestItem.additionalData
              .expiryDate +
            ")";
          return cardInfo;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return "";
  }

  function ShowVoid(row: any) {
    if (
      props.paymentData.void &&
      totalRows == 1 &&
      voidClicked == false &&
      row.CancelOrRefund != true &&
      !voidButton
    ) {
      let timerId = setTimeout(async function VoidPayment() {
        voidButton.current.click();
        voidClicked = true;
      }, 200);
    }
    if (row.CancelOrRefund) return true;
    if (row.Status == "Success") return false;
    return true;
  }

  async function ConfirmVoidTransaction(e: any, row: any) {
    setVoidTransaction(true);
    setAnchorVoid(e.currentTarget);
    setCurrentRow(row);
  }

  function ShowPaymentResponse(e: any, row: any) {
    if (currentRow.InvoiceId == row.InvoiceId) {
      SetCardClickCount(cardClickCount + 1);
      if (cardClickCount > 3) {
        setShowDetail(true);
        setAnchorEl(e.currentTarget);
      }
    } else {
      SetCardClickCount(0);
    }
    setCurrentRow(row);
  }

  function ShowPagination() {
    if (!isEmpty(props.paymentData) && totalRows < rowsPerPage) return false;
    return true;
  }

  function CheckActiveTerminal(row: any): any {
    if (isEmpty(row.lastActivityAt)) return "";
    var date1: any = Date.parse(row.lastActivityAt);
    var data2: any = new Date();
    var diffMs = data2 - date1; // milliseconds between now & Christmas
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (diffMins < 60) return `(Active ${diffMins}m)`;
    return "";
  }

  function DealerLocations() {
    return (
      // <FormControl sx={{ mr: 1, minWidth: 150 }} size="small">
      //     <InputLabel id="filter-type-label"></InputLabel>
      //     <Select
      //         autoWidth
      //         labelId="filter-type-label"
      //         id="filter-type-select"
      //         value={dealerLocation}
      //         onChange={(e) => setDealerLocation(e.target.value as string)}
      //         label=""
      //     >
      //         <MenuItem value="0">All Locations</MenuItem>
      //         {
      //             dealerLocations.map((row: any) => (
      //                 // <MenuItem value={row.id}>{row.id}</MenuItem>
      //                 <MenuItem value={row.DealerId}>{row.Name}
      //                 </MenuItem>
      //             ))
      //         }
      //     </Select>
      // </FormControl>
      <Select
        size="small"
        sx={{ m: 1, minWidth: 200, background: "white" }}
        value={dealerLocation}
        onChange={(e) => setDealerLocation(e.target.value as string)}
      >
        <MenuItem value="0">All Locations</MenuItem>
        {dealerLocations?.map((option: any, index: any) => (
          <MenuItem key={index} value={option.DealerId}>
            {option.DealerId} - {option.Name}
          </MenuItem>
        ))}
      </Select>
    );
  }

  const handleDateValueChange = (value: [Date, Date] | null) => {
    //const handleDateValueChange = (value: [Date, Date]) => {
    // setDateRange(value);
    if (value) {
      setDateRange(value);
    } else {
      // setDateRange(value); // Clear the filter
      setDateRange([startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]);
    }
  };

  const handleClose = () => {
    //setDateRange(null); // Clear the filter
  };

  return (
    <Box>
      <Box>
        <Paper>
          <Box>
            {isEmpty(props.paymentData) && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={0}
              >
                <Typography variant="h6" gutterBottom>
                  Transactions
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Box style={{ marginLeft: "100px" }}>
                    <DateRangePicker
                      character=" -- "
                      ranges={Ranges}
                      size="lg"
                      placeholder="Date Range"
                      value={dateRange}
                      onChange={handleDateValueChange}
                      onClean={handleClose}
                      format="M-d-yyyy"
                      placement="autoVertical"
                    />
                  </Box>
                  <DealerLocations></DealerLocations>
                  <TextField
                    id="outlined-search"
                    placeholder="Search"
                    // sx={{ width: "50ch" }}
                    sx={{ width: "200px" }}
                    size="small"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Stack>
            )}
          </Box>
          <TableContainer style={{ marginTop: "10px" }}>
            <Table
              // sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              {/* <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                // onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                // rowCount={paymentData.Count}
                                rowCount={totalRows}
                            /> */}
              <TableHead>
                <TableRow style={{ backgroundColor: "#F2F3F4" }}>
                  {/* <TableCell>Order#</TableCell> */}
                  <TableCell>
                    {!isEmpty(props.paymentData) ? "Payment#" : "Order#"}
                  </TableCell>
                  {isEmpty(props.paymentData) && (
                    <TableCell>Customer</TableCell>
                  )}
                  {/* {isEmpty(props.paymentData) && (<TableCell>Transaction Date</TableCell>)} */}
                  <TableCell>Transaction Date</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell>Status</TableCell>
                  {/* <TableCell>Card</TableCell> */}
                  {dealerLocation == 0 && <TableCell>Location</TableCell>}

                  {props.paymentData && props.paymentData.void && (
                    <TableCell>Action</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {PaymentData().map((row: any, index: any) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <TableRow
                      style={{
                        backgroundColor:
                          currentRow != null &&
                          row.PaymentId == currentRow.PaymentId
                            ? "#E9F7EF"
                            : "",
                      }}
                      hover
                      // onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: "pointer" }}
                    >
                      {/* <TableCell>{row.InvoiceId}</TableCell> */}
                      <TableCell
                        style={{
                          textDecorationLine: row.CancelOrRefund
                            ? "line-through"
                            : "",
                        }}
                      >
                        {!isEmpty(props.paymentData)
                          ? row.PaymentId
                          : row.InvoiceId}
                      </TableCell>
                      {isEmpty(props.paymentData) && (
                        <TableCell>
                          {row.CustomerName} ({row.CustomerId})
                        </TableCell>
                      )}
                      {/* {isEmpty(props.paymentData) && (<TableCell>{moment.utc(row.DateCreated).local().format('M/D/YYYY h:mm A')}</TableCell>)} */}
                      <TableCell>
                        {isEmpty(row.DateProcessed)
                          ? row.DateCreated.toUSDateFormat()
                          : row.DateProcessed.toUSDateFormat()}
                      </TableCell>
                      <TableCell align="right">
                        {dollarUSLocale.format(row.RequestedAmount)}
                      </TableCell>
                      <TableCell>
                        {row.CancelOrRefund ? "Voided" : row.Status}
                      </TableCell>
                      {/* <TableCell>{PaymentType(row)}</TableCell> */}
                      {/* <TableCell onClick={(e) => ShowPaymentResponse(e, row)}>{CardInfo(row)}</TableCell> */}
                      {dealerLocation == 0 && (
                        <TableCell>{row.DealerName}</TableCell>
                      )}
                      {props.paymentData && props.paymentData.void && (
                        <TableCell align="center">
                          <Button
                            ref={voidButton}
                            disabled={ShowVoid(row)}
                            variant="outlined"
                            color="error"
                            style={{ textTransform: "none", padding: "00px" }}
                            onClick={(e) => ConfirmVoidTransaction(e, row)}
                          >
                            Void
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
                {emptyRows() > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows(),
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {ShowPagination() && (
            <TablePagination
              rowsPerPageOptions={[10, 15, 25, 50]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
        {isEmpty(props.paymentData) && (
          <Box>
            <Button
              onClick={() => {
                setOpenDrawer(true);
                setOpenTag("showconfig");
              }}
            >
              Config
            </Button>
            <Button
              onClick={() => {
                setOpenDrawer(true);
                setOpenTag("transferTransactions");
              }}
            >
              Transfers
            </Button>
            <Button
              onClick={() => {
                setOpenDrawer(true);
                setOpenTag("Report1");
              }}
            >
              Report
            </Button>
          </Box>
        )}
        <Drawer
          PaperProps={{ style: { width: "auto", minWidth: "20%" } }}
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          variant={"temporary"}
        >
          {openTag == "showconfig" && <StoreConfig></StoreConfig>}
          {openTag == "transferTransactions" && (
            <TransferTransactions></TransferTransactions>
          )}
          {openTag == "Report1" && <Report1></Report1>}
        </Drawer>
      </Box>
      <Popover
        open={showDetail}
        // open={true}
        anchorEl={anchorEl}
        // onClose={handleCloseOrderDetail}
        onClose={(e) => setShowDetail(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box>
          <div style={{ padding: "5px 10px" }}>
            {currentRow.AdyenResponse && (
              <Box>
                <pre>
                  {JSON.stringify(
                    JSON.parse(currentRow.AdyenResponse),
                    null,
                    2
                  )}
                </pre>
              </Box>
            )}
          </div>
        </Box>
      </Popover>
      <Popover
        style={{ marginTop: "10px" }}
        open={voidTransaction}
        // open={true}
        anchorEl={anchorVoid}
        // onClose={handleCloseOrderDetail}
        onClose={(e) => setVoidTransaction(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {props.terminals && (
          <Box>
            <div>
              <Box>
                <Box style={{ padding: "3px 5px", backgroundColor: "#F2F4F4" }}>
                  <h6>Please confirm</h6>
                </Box>
                <Box style={{ padding: "10px 20px" }}>
                  <Box>
                    Are you sure you want to void this payment transaction?
                    <br></br>Amount{" "}
                    {dollarUSLocale.format(currentRow.RequestedAmount)}
                  </Box>
                  <Box style={{ marginTop: "15px" }}>
                    <FormControl sx={{ mr: 1, minWidth: 150 }} size="small">
                      <InputLabel id="filter-type-label">
                        Payment Terminal
                      </InputLabel>
                      <Select
                        labelId="filter-type-label"
                        id="filter-type-select"
                        value={props.terminal}
                        onChange={(e) => props.onTerminalChange(e.target.value)}
                        label="Payment Terminal"
                      >
                        <MenuItem value="0">Choose One</MenuItem>
                        {props.terminals.map((row: any, index: any) => (
                          <MenuItem value={row.id} key={index}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>{row.id}</td>
                                  <td>
                                    <Box
                                      style={{
                                        marginLeft: "8px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      {CheckActiveTerminal(row)}
                                    </Box>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box style={{ marginTop: "20px", textAlign: "center" }}>
                    <Button
                      ref={voidNowButton}
                      disabled={DisableVoidNow()}
                      variant="contained"
                      onClick={VoidTransaction}
                    >
                      Yes, void now
                    </Button>
                    <Button
                      variant="contained"
                      onClick={(e) => setVoidTransaction(false)}
                      style={{ marginLeft: "15px" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Box>
                    {adyenResponse && adyenResponse.result != "Success" && (
                      <Box
                        style={{
                          maxWidth: "400px",
                          margin: "20px 10px 10px 10px",
                        }}
                      >
                        <Paper style={{ padding: "5px", color: "red" }}>
                          {adyenResponse.result}
                          {adyenResponse.additionalResponse}
                        </Paper>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              {/* {currentRow.result && (
                    <Box>
                      <pre>{JSON.stringify(JSON.parse(currentRow.result), null, 2)}</pre>
                    </Box>

                  )} */}
            </div>
          </Box>
        )}
      </Popover>
    </Box>
  );
}
