import { DateRangePickerProps } from "rsuite";
import DateRangePicker from "rsuite/DateRangePicker";
import { format, startOfDay, endOfDay, addDays, subDays, subMonths, startOfWeek, endOfWeek } from "date-fns";

import { TitleCase } from "../../utils/Util";
import moment from "moment";
import { useEffect, useState } from "react";
import * as React from 'react';
import axios from 'axios';
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import {
    Box, Button, Drawer, FormControl, IconButton, InputLabel, Link, MenuItem, Paper, Popover, Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    useTheme
} from "@mui/material";
import { Helmet } from "react-helmet-async";
// import { Callback } from "../../utils/Util";

let numberUSLocale = Intl.NumberFormat("en-US", {
    // style: "currency",
    // currency: "USD",
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); // $148,000

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number
    ) => void;
}
function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const Ranges: DateRangePickerProps["ranges"] = [
    {
        label: "Today",
        value: [startOfDay(new Date()), endOfDay(new Date())],
        placement: "left",
    },

    {
        label: "Yesterday",
        value: [
            startOfDay(addDays(new Date(), -1)),
            endOfDay(addDays(new Date(), -1)),
        ],
        placement: "left",
    },

    {
        label: "Last 7 Days",
        value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
        placement: "left",
    },

    {
        label: "This Week",
        value: [startOfWeek(new Date()), endOfWeek(new Date())],
        placement: "left",
    },

    {
        label: "Next Week",
        closeOverlay: false,
        value: (value) => {
            const [start = new Date()] = value || [];
            return [
                addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
                addDays(endOfWeek(start, { weekStartsOn: 0 }), 7),
            ];
        },
        placement: "left",
    },
];






export default function TransferTransactions() {
    const [transferData, setTransferData] = React.useState<any>([]);
    const [currentRow, setCurrentRow] = useState([] as any);
    const [showDetail, setShowDetail] = React.useState(false);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [totalRows, setTotalRows] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [dateRange, setDateRange] = useState<[Date, Date]>([
        startOfDay(subMonths(new Date(), 1)),
        endOfDay(new Date()),
    ]);


    useEffect(() => {
        // document.title = 'Transfer Transactions';
        GetTransferPayments();

    }, [page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };




    function GetTransferPayments() {
        let obj: any = new Object();
        obj.PageNumber = page;
        obj.PageSize = rowsPerPage;
        // obj.MachineName = "KarticLT";
        let jsonString = JSON.stringify(obj);
        // alert(jsonString);
        let url = `${process.env.REACT_APP_EVEREST_URL}api/Query`;
        try {
            axios.post(url, {
                "query": "PerseusPay.TransferTransactions",
                "queryBody": jsonString
            }, {
                auth: {
                    username: `${process.env.REACT_APP_EVEREST_USERNAME}`,
                    password: `${process.env.REACT_APP_EVEREST_PASSWORD}`

                }
            }).then(function (response) {
                // alert(response.data.Data);
                setTransferData([]);
                if (response.data.Data != null) {
                    // alert(response.data.Data.length );
                    setTransferData(response.data.Data);
                    setTotalRows(page == 0 ? response.data.Count : totalRows);
                    // chrome.webview.postMessage("message");
                    // window.postMessage("") ;
                }
            }).catch(function (error) {
                alert(error);
            });
        }
        catch (err) {
            alert(err);
        }
    }

    function GetData(content: any): any {
        return JSON.parse(content);
    }

    function ShowDetail(e: any, row: any) {
        setCurrentRow(row);
        setShowDetail(true);
        // setAnchorEl(e.currentTarget);
    }

    function SelectedRow(row: any): any {
        try {
            if (currentRow == null) return false;
            return JSON.parse(row.content).data.id == JSON.parse(currentRow.content).data.id;
            return true;
        }
        catch (error) {
            return false;
        }
    }
    const handleDateValueChange = (value: [Date, Date] | null) => {
        //const handleDateValueChange = (value: [Date, Date]) => {
        // setDateRange(value);
        if (value) {
            setDateRange(value);
        } else {
            // setDateRange(value); // Clear the filter
            setDateRange([
                startOfDay(subDays(new Date(), 6)),
                endOfDay(new Date()),
            ])
        }
    };

    const handleClose = () => {
        //setDateRange(null); // Clear the filter
    };

    function GetBalanceAmount(data:any):any{
        // if(data.balances==null) return 1;
        // //if(balances.balance==null) return 2;
        // if(data.balances.length==0) return 3;
        if(data.balances!=null && data.balances.length>0)return data.balances[0].balance;
        return data.amount.value;
        
        // return 123;

    }


    return (
        <Box display="flex" justifyContent="center" alignItems="center" style={{ padding: "10px" }}>
            <Box>
                <Paper>
                    <Box style={{ padding: "5px" }}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={0}
                        >

                            <Typography variant="h6" gutterBottom>
                                Transfer Transactions
                            </Typography>
                            <Stack direction="row" alignItems="center">
                                <Box mr={4}>
                                    <DateRangePicker
                                        character=" -- "
                                        ranges={Ranges}
                                        size="lg"
                                        placeholder="Date Range"
                                        value={dateRange}
                                        onChange={handleDateValueChange}
                                        onClean={handleClose}
                                        format="M-d-yyyy"
                                        placement="autoVertical"
                                    />
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                    <TableContainer>
                        <Table
                            // sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={'small'}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Transaction Date</TableCell>
                                    <TableCell>Transfer Id</TableCell>
                                    <TableCell>Amount</TableCell>
                                    {/* <TableCell>Reference</TableCell> */}
                                    <TableCell>Reason</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Balance Account</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transferData.map((row: any) => {
                                    return (
                                        <TableRow
                                            // style={{ backgroundColor: SelectedRow(row) ? '#E9F7EF' : "" }}
                                            hover
                                            onClick={(event) => setCurrentRow(row)}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell>{TitleCase(JSON.parse(row.Content).data.status)}</TableCell>
                                            <TableCell>{moment.utc(JSON.parse(row.Content).data.creationDate).local().format('M/D/YYYY h:mm A')}</TableCell>
                                            <TableCell><Link onClick={(e) => ShowDetail(e, row)}>{JSON.parse(row.Content).data.id}</Link></TableCell>
                                            <TableCell>{JSON.parse(row.Content).data.amount.currency} {numberUSLocale.format(GetBalanceAmount(JSON.parse(row.Content).data) / 100)}</TableCell>
                                            <TableCell>{TitleCase(JSON.parse(row.Content).data.reason)}</TableCell>
                                            <TableCell>{JSON.parse(row.Content).data.description}</TableCell>
                                            <TableCell>{JSON.parse(row.Content).data.balanceAccount.description}</TableCell>

                                        </TableRow>
                                    );

                                })}
                            </TableBody>

                        </Table>

                    </TableContainer>
                    {/* <TablePagination
                        rowsPerPageOptions={[15, 20, 25, 50]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                    <Box display="flex" alignItems="center" justifyContent="end">
                        <TablePagination
                            rowsPerPageOptions={[10, 15, 25, 50]}
                            count={totalRows}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    "aria-label": "rows per page",
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Box>

                </Paper>
            </Box>
            <Drawer
                PaperProps={{ style: { width: 'auto', minWidth: "20%" } }}
                anchor="right"
                open={showDetail}
                onClose={() => setShowDetail(false)}
                variant={'temporary'}
            >
                {showDetail && (
                    <Box>
                        {(currentRow && currentRow.Content) && (
                            <Box>
                                <pre>{JSON.stringify(JSON.parse(currentRow.Content), null, 2)}</pre>
                            </Box>
                        )}

                    </Box>


                )}
            </Drawer>

        </Box>

    )
}