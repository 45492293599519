import { addDays, endOfDay, startOfDay } from "date-fns";
import { DealerProfile, TargetPayApi } from "../../utils/UtilTarget";
import { endOfDayWithoutTimezone, startOfDayWithoutTimezone } from "../../utils/Util";

export interface GetTransactionRequest {
	dealerLocationId: number, 
	page: number, 
	rowsPerPage: number, 
	fromDate: Date, 
	toDate: Date, 
	searchCriteria: any,
	sortField: string,
	sortOrder: string
}

export class TransactionDataLoader {

	public static async GetTransactions(request: GetTransactionRequest): Promise<any> {
		try {
			let obj = {
				"dealerId": request.dealerLocationId == -1 ? 0: request.dealerLocationId,
				"companyDealerId": request.dealerLocationId == -1 ? await DealerProfile("companyDealerId") : 0,
				"Page": request.page,
				"PageSize": request.rowsPerPage,
				"fromDate": startOfDayWithoutTimezone(request.fromDate),
				"toDate": endOfDayWithoutTimezone(request.toDate),
				"searchCriteria": request.searchCriteria,
				"sortField": request.sortField,
				"sortDirection": request.sortOrder,
			};

			var resp = await TargetPayApi(``, obj, "Get", "Transaction");
			return (resp);
		}
		catch (error) {
			alert(error);
		}
	}
}