import { useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { EverestApi, TargetPayApi } from "../../utils/UtilTarget";
import { isEmpty } from "../../utils/Util";
import React from "react";
const emails = ["username@gmail.com", "user02@gmail.com"];

export interface AuditTrailTimelineHelpDialogPorps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
  timelines?: any;
}

export default function AuditTrailTimelineHelp(
  props: AuditTrailTimelineHelpDialogPorps
) {
  const { onClose, selectedValue, open, timelines } = props;
  const [adyenStatus, setAdyenStatus] = React.useState<any>(null);
  const [showAll, setShowAll] = React.useState<any>(true);

  const handleClose = () => {
    onClose(selectedValue);
  };

  useEffect(() => {
    if (open) GetAllStatus();
  }, [open]);

  async function GetAllStatus() {
    let obj: any = new Object();

    obj.query = "PerseusPay.AdyenStatus";
    obj.queryBody = "{}";

    const res = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );
    if (!isEmpty(res)) {
      // //   for (let i = 0; i < props.timelines.length; i++) {
      // //     let foundItem = res.find((item) => (item.PaymentResponseTypeID === props.timelines[i].paymentResponseTypeID || item.Status === props.timelines[i].paymentResponseTypeID));
      // //     if (!isEmpty(foundItem)) { foundItem.inTimeline = 1 }
      // //   }
      setAdyenStatus(res);
    }
  }
  function GetStatus(): any {
    if (isEmpty(adyenStatus)) return [];
    if (showAll) return adyenStatus;
    let items = adyenStatus.filter((item) => item.inTimeline == 1);
    if (items.length == 0) return adyenStatus;
    return items;
  }

  return (
    <Dialog maxWidth="lg" fullWidth={true} onClose={handleClose} open={open}>
      <DialogTitle>Transaction Timeline</DialogTitle>
      <div
        style={{
          position: "absolute",
          right: 10,
          top: 10,
        }}
      >
        {timelines && timelines.length > 0 && (
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={showAll}
                onChange={(e) => {
                  setShowAll(e.currentTarget.checked);
                }}
              />
            }
            label="Show all status"
            style={{ display: "none" }}
          />
        )}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent dividers className="timeline-help">
        {!isEmpty(GetStatus()) &&
          GetStatus().map((row: any, index: number) => {
            return (
              <div key={index}>
                <h6>{row.Status}</h6>
                <p>{row.Description}</p>
                <br />
              </div>
            );
          })}
      </DialogContent>
    </Dialog>
  );
}
