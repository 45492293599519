import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

export default function ProgressLoader(props){
    return(     
        (props.isFetching &&
            <Box 
            display="flex"
            justifyContent="center"
            alignItems="center"
            height= "88%"
            minHeight="88%">
                <CircularProgress size="2rem" color="info" />
            </Box>
        )
    );
}