import { addDays, endOfDay, startOfDay, subMonths, subWeeks } from "date-fns";
import {
  DealerProfile,
  EverestApi,
  TargetPayApi,
} from "../../utils/UtilTarget";
import {
  endOfDayWithoutTimezone,
  startOfDayWithoutTimezone,
} from "../../utils/Util";

export class DashboardDataLoader {
  public static async GetPayments(
    dateCriteria: any,
    dealerLocationsID: number,
    pageSize: Number
  ): Promise<any> {
    try {
      let obj: any = new Object();
      let objBody: any = new Object();

      obj.query = "PerseusPay.Payments";

      if (dealerLocationsID == -1) {
        objBody.CompanyDealerId = await DealerProfile("companyDealerId");
      } else {
        objBody.DealerId = dealerLocationsID;
      }

      objBody.Search = "";
      objBody.PageNumber = 0;
      objBody.PageSize = pageSize;
      objBody.StartDate = dateCriteria[0];
      objBody.EndDate = dateCriteria[1];

      obj.queryBody = JSON.stringify(objBody);

      const response = await TargetPayApi(
        "query",
        JSON.stringify(obj),
        "post",
        "Everest"
      );
      return response;
    } catch (error) {
      alert(error);
    }
  }

  public static async GetTransactionMatrix(
    dateCriteria: any,
    dealerLocationsID: number,
    pageSize: Number
  ): Promise<any> {
    try {
      let obj: any = new Object();

      if (dealerLocationsID == -1) {
        obj.CompanyDealerID = await DealerProfile("companyDealerId");
      } else {
        obj.DealerID = dealerLocationsID;
      }
      obj.FromDate = dateCriteria[0];
      obj.ToDate = dateCriteria[1];

      var resp = await TargetPayApi(`Matrix`, obj, "Get", "Dashboard");
      return resp;
    } catch (error) {
      alert(error);
    }
  }

  public static async GetDealerLocations(dealerId): Promise<any> {
    try {
      let obj: any = new Object();
      let objBody: any = new Object();

      obj.query = "GetDealer";
      objBody.DealerId = dealerId;

      obj.queryBody = JSON.stringify(objBody);

      const response = await TargetPayApi(
        "query",
        JSON.stringify(obj),
        "post",
        "Everest"
      );
      return response;
    } catch (error) {
      alert(error);
    }
  }

  public static async GetCardBrandUsageData(
    dateCriteria: any,
    dealerLocationsID: number
  ): Promise<any> {
    try {
      let obj: any = new Object();

      if (dealerLocationsID == -1) {
        obj.CompanyDealerID = await DealerProfile("companyDealerId");
      } else {
        obj.DealerID = dealerLocationsID;
      }
      obj.FromDate = dateCriteria[0];
      obj.ToDate = dateCriteria[1];

      var resp = await TargetPayApi(`CardBrandUsage`, obj, "Get", "Dashboard");
      return resp;
    } catch (error) {
      alert(error);
    }
  }

  public static async GetPageSettings(): Promise<any> {
    try {
      var resp = await TargetPayApi(
        "GetByPage/transaction",
        undefined,
        "Get",
        "PageSettings"
      );
      return resp;
    } catch (error) {
      alert(error);
    }
  }
}
