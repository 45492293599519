import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
  isAuthenticated: () => boolean;
  element: React.ReactNode;
}

export function PrivateRoute({ element, isAuthenticated }: PrivateRouteProps) {
  // alert('PrivateRouteProps');
  localStorage.setItem("postLogin", window.location.pathname);
  // alert(window.location.pathname);
  return isAuthenticated() ? <>{element}</> : <Navigate to="/login" replace />;
}
