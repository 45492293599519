import { useEffect } from "react";

export default function Test1() {
    useEffect(() => {
    }, []);
    return (
        <div>
            <iframe style={{ width: "700px", height: "500px" }} title="static_html" src="emergePay.html?isSandBox=1&transactionToken=f8e3c784567142f292439d5a1014501b"></iframe>
        </div>
    )

}