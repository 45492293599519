import { Navigate, useRoutes } from "react-router-dom";
import { PrivateRoute } from "./utils/PrivateRoutes";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
// pages
import DashboardApp from "./pages/DashboardApp";
import DashboardMain from "./pages/DashboardApp/DashboardMain";
import Transaction from "./pages/Transaction";
import User from "./pages/User";
import Settings from "./pages/Settings";
import Page404 from "./pages/Page404";
import Page401 from "./pages/Page401";
import Login from "./pages/Login";
import Redirect from "./pages/Redirect";
import VaultedCard from "./pages/VaultedCard";
import PaymentPage from "./pages/PaymentPage";
import { useCheckAuthToken } from "./hooks/useCheckAuthToken";
// import TestKG1 from "./pages/TestKG/TestKG1";
import AdyenTransactions from "./pages/PerseusPay/AdyenTransactions";
import Payment from "./pages/PerseusPay/Payment";
import TransferTransactions from "./pages/PerseusPay/TransferTransactions";
import Report1 from "./pages/PerseusPay/Report1";
import AdyenPayments from "./pages/PerseusPay/AdyenPaymentDropIn";
import PaymentContainer from "./pages/PerseusPay/AdyenPaymentDropIn";
import OnBoarding from "./pages/PerseusPay/OnBoarding/OnBoarding";
import PreOnBoarding from "./pages/PerseusPay/OnBoarding/PreOnBoarding";
import Terminals from "./pages/PerseusPay/Terminals";
import TerminalSettings from "./pages/PerseusPay/TerminalSettings";
import Adyen from "./pages/PerseusPay/Adyen";
import Test1 from "./pages/PerseusPay/Test1";
import { Terminal } from "@mui/icons-material";
import { useCheckTLDomainUser } from "./hooks/useCheckTLDomainUser";
import OnBoardingStatus from "./pages/PerseusPay/OnBoarding/OnBoardingStatus";
import DailyReconciliationReport from "./pages/PerseusPay/Reports/DailyReconciliationReport";

// ----------------------------------------------------------------------

export default function Router() {
  const isTLDomainUser = useCheckTLDomainUser(); // Get the value of isTLDomainUser

  const routes = useRoutes([
    // {
    //   element: <PrivateRoute
    //     element={<Adyen />}
    //     isAuthenticated={useCheckAuthToken}
    //   />, path: "/adyen"
    // },
    // {
    //   path: "/adyen", children: [
    //     {
    //       path: "transaction",
    //       element: <PrivateRoute
    //         element={<Adyen />}
    //         isAuthenticated={useCheckAuthToken}

    //       />
    //     }

    //   ]
    // },
    {
      path: "test",
      element: <Test1 />,
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },

        {
          path: "app",
          element: !isTLDomainUser ? (
            <PrivateRoute
              // element={<DashboardApp />}
              // isAuthenticated={useCheckAuthToken}
              element={<DashboardMain />}
              isAuthenticated={useCheckAuthToken}
            />
          ) : (
            <Navigate to="/login" />
          ),
        },

        {
          path: "transactions",
          element: !isTLDomainUser ? (
            <PrivateRoute
              element={<Transaction />}
              isAuthenticated={useCheckAuthToken}
            />
          ) : (
            <Navigate to="/login" />
          ),
        },

        {
          path: "users",
          element: !isTLDomainUser ? (
            <PrivateRoute
              element={<User />}
              isAuthenticated={useCheckAuthToken}
            />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "settings/:dealerId",
          element: !isTLDomainUser ? (
            <PrivateRoute
              element={<Settings />}
              isAuthenticated={useCheckAuthToken}
            />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "perseuspaysetting",
          element: !isTLDomainUser ? (
            <PrivateRoute
              element={<OnBoarding />}
              isAuthenticated={useCheckAuthToken}
            />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "terminalsetting",
          element: !isTLDomainUser ? (
            <PrivateRoute
              // element={<TerminalSettings />}
              element={<Terminals />}
              isAuthenticated={useCheckAuthToken}
            />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "dailyReconciliationReport",
          element: !isTLDomainUser ? (
            <PrivateRoute
              element={<DailyReconciliationReport />}
              isAuthenticated={useCheckAuthToken}
            />
          ) : (
            <Navigate to="/login" />
          ),
        },

        {
          path: "preonboarding",
          element: isTLDomainUser ? (
            <PrivateRoute
              element={<PreOnBoarding />}
              isAuthenticated={useCheckAuthToken}
            />
          ) : (
            <Navigate to="/login" />
          ),
        },

        {
          path: "onboardingStatus",
          element: isTLDomainUser ? (
            <PrivateRoute
              element={<OnBoardingStatus />}
              isAuthenticated={useCheckAuthToken}
            />
          ) : (
            <Navigate to="/login" />
          ),
        },

        {
          path: "adyen-payments",
          element: !isTLDomainUser ? (
            <PrivateRoute
              element={
                <AdyenPayments
                  token={""}
                  onPaymentStarted={null}
                  onPaymentCompleted={null}
                  saveCard={false}
                />
              }
              isAuthenticated={useCheckAuthToken}
            />
          ) : (
            <Navigate to="/login" />
          ),
        },

        // {
        //   path: "vaulted-card",
        //   element: (
        //     <PrivateRoute
        //       element={<VaultedCard />}
        //       isAuthenticated={useCheckAuthToken}
        //     />
        //   ),
        // },
      ],
    },
    // ---

    // ---
    {
      path: "/adyentransactions",
      element: !isTLDomainUser ? (
        <AdyenTransactions />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/onboarding",
      element: !isTLDomainUser ? <OnBoarding /> : <Navigate to="/login" />,
    },

    {
      path: "/payment",
      element: !isTLDomainUser ? <Payment /> : <Navigate to="/login" />,
    },

    {
      path: "settings/:dealerId",
      element: !isTLDomainUser ? <Settings /> : <Navigate to="/login" />,
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        // {
        //   path: "vaulted-card",
        //   element: <VaultedCard />,
        // },
        // {
        //   path: "settings/:dealerId",
        //   element: <Settings />,
        // },
        { path: "*", element: <Navigate to="/404" /> },
        { path: "*", element: <Navigate to="/401" /> },
      ],
    },

    {
      path: "payment-page",
      element: !isTLDomainUser ? <PaymentPage /> : <Navigate to="/login" />,
    },

    {
      path: "redirect",
      element: <Redirect />,
    },

    {
      path: "login",
      element: <Login />,
    },

    {
      path: "401",
      element: <Page401 />,
    },

    {
      path: "404",
      element: <Page404 />,
    },

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
