import { Card } from "@mui/material";
import { Box, bgcolor } from "@mui/system";
import { convertToUSD } from "../../utils/Util";
import HtmlTooltip from "../../common/components/htmltooltip";

export default function DashboardMatrix({ paymentData }) {
    return (
        <Box style={{ "display": "flex", border: "0px solid red", marginTop: "10px" }}>
            <Box style={{ flex: "33%", lineHeight: "2.5" }}>
                <Card
                    className="boxShadow"
                    sx={{
                        py: 1,
                        boxShadow: 0,
                        borderRadius: "10px",
                        textAlign: "center",
                        color: "white",
                        bgcolor: "#1A237E"
                    }}>
                    <label style={{ fontSize: "large", fontWeight: "bold" }}> {convertToUSD(Number(paymentData?.totalAmountSucceeded))}</label> <br />
                    <label> Total Amount Succeeded
                        <HtmlTooltip
                            placement="right"
                            content={["Includes only successful transactions"]}
                            iconStyles={{ color: "#e0e0e0" }}>
                        </HtmlTooltip>
                    </label>
                </Card>
            </Box>
            <Box style={{ flex: "33%", marginLeft: "20px", lineHeight: "2.5" }}>
                <Card
                    className="boxShadow"
                    sx={{
                        py: 1,
                        boxShadow: 0,
                        borderRadius: "10px",
                        textAlign: "center",
                        color: "white",
                        bgcolor: "#FF0000"
                    }}>
                    <label style={{ fontSize: "large", fontWeight: "bold" }}> {convertToUSD(Number(paymentData?.totalRefunded))}</label> <br />
                    <label> Total Amount Refunded
                        <HtmlTooltip
                            placement="right"
                            content={["Includes only refunded transactions"]}
                            iconStyles={{ color: "#e0e0e0" }}>
                        </HtmlTooltip>
                    </label>
                </Card>
            </Box>
            <Box style={{ flex: "33%", marginLeft: "20px", lineHeight: "2.5" }}>
                <Card
                    className="boxShadow"
                    sx={{
                        py: 1,
                        boxShadow: 0,
                        borderRadius: "10px",
                        textAlign: "center",
                        color: "white",
                        bgcolor: "#FFA500"
                    }}>
                    <label style={{ fontSize: "large", fontWeight: "bold" }}> {paymentData?.totalTransactionCount ? paymentData?.totalTransactionCount : 0}</label> <br />
                    <label>Total Transaction Count
                        <HtmlTooltip
                            placement="right"
                            content={["Includes all transactions"]}
                            iconStyles={{ color: "#e0e0e0" }}>
                        </HtmlTooltip>
                    </label>
                </Card>
            </Box>
            <Box style={{ flex: "33%", marginLeft: "20px", lineHeight: "2.5" }}>
                <Card
                    className="boxShadow"
                    sx={{
                        py: 1,
                        boxShadow: 0,
                        borderRadius: "10px",
                        textAlign: "center",
                        color: "white",
                        bgcolor: "#008000"
                    }}>
                    <label style={{ fontSize: "large", fontWeight: "bold" }}> {paymentData?.totalApprovedTransactions ? paymentData?.totalApprovedTransactions : 0}</label> <br />
                    <label>  Total Approved Transactions
                        <HtmlTooltip
                            placement="right"
                            content={["Includes only successful and refunded transactions"]}
                            iconStyles={{ color: "#e0e0e0", size: "20px" }}>
                        </HtmlTooltip>
                    </label>
                </Card>
            </Box>
        </Box>
    );
}