import { TableCell, TableHead, TableRow } from "@mui/material";
import { useState } from "react";

const EnhancedTableHead = ({ currentSortField, currentSortOrder, columns, handleSorting }) => {
  const [sortField, setSortField] = useState(currentSortField);
  const [order, setOrder] = useState(currentSortOrder);

  const handleSortingChange = (accessor) => {
	const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map(({ label, accessor, sortable, align, display, width }) => {
          const cl = sortable
            ? sortField === accessor && order === "asc"
              ? "sortasc"
              : sortField === accessor && order === "desc"
              ? "sortdesc"
              : "sortdefault"
            : "";
          return (
            <TableCell
              key={accessor}
			  align={align ? align: "left"}              
              className={cl}
			  style={{ display: display, width: width, whiteSpace: "nowrap" }}
			  onClick={sortable ? () => handleSortingChange(accessor) : null}>
              {label}&nbsp;
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
