import * as React from "react";
import { PaymentMethodType } from "../../types/enums";
import { de } from "date-fns/locale";

export default function PaymentMehtodType(props: {type: PaymentMethodType}){
    let imageName = "";
    let imageTitle = ""
    switch(props.type)
    {
        case PaymentMethodType.Visa:
            imageName = "PM_VISA.PNG";
            imageTitle = "Visa"
        break;
        
        case PaymentMethodType.Maestro:
            imageName = "PM_Maestro.PNG";
            imageTitle = "Maestro";
        break;            

        case PaymentMethodType.MasterCard:
            imageName = "PM_MasterCard.PNG";
            imageTitle = "Mastercard"
        break; 

        case PaymentMethodType.AmericanExpress:
            imageName = "PM_AmericanExpress.PNG";
            imageTitle = "American Express"
        break; 

        case PaymentMethodType.Discover:
            imageName = "PM_Discover.PNG";
            imageTitle = "Discover"
        break; 

        case PaymentMethodType.Diners:
            imageName = "PM_DinersClub.PNG";
            imageTitle = "Diners"
        break; 

		default: 
            imageName = "no_image.png";
            imageTitle = "unknown card"
        break; 
	}

    return (
        (props.type && <img src={"/images/" + imageName} height="20px" width="35px" title={imageTitle} />)
    );
}