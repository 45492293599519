import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { isEmpty } from "../../../utils/Util";
import React, { useEffect } from "react";
import { Drawer } from "rsuite";
import { TargetPayApi } from "../../../utils/UtilTarget";
import { StyleCreator } from "../../../helpers/stylecreator";
import { FormStyleCollection as formStyles } from "../../../styles/form";
import { toast } from "react-toastify";

interface DrawerProps {
  open: boolean;
  onClose: () => void;
  dealerDetail: any;
  onAddPriceModel: any;
}

export default function PricingModel({
  open,
  onClose,
  onAddPriceModel,
  dealerDetail,
}) {
  let dealerId = dealerDetail.DealerId;
  let merchantId = dealerDetail.MerchantId;

  const [ModelTypeMsg, setModelTypeMsg] = React.useState<any>("");
  const [MarkupRateMsg, setMarkupRateMsg] = React.useState<any>("");
  const [MarkupFeeMsg, setMarkupFeeMsg] = React.useState<any>("");
  const [MonthlyFeeMsg, setMonthlyFeeMsg] = React.useState<any>("");
  const [isProcessing, setIsProcessing] = React.useState<any>(false);
  const [DealerId, SetDealerId] = React.useState<any>(0);
  const [MerchantId, SetMerchantId] = React.useState<any>("");
  const [PricingModelType, SetPricingModelType] = React.useState<any>([
    {
      Description: "Choose One",
    },
    { Description: "Flat Rate" },
    { Description: "Interchange Plus" },
  ]);

  const [PricingModels, SetPricingModels] = React.useState<any>({
    MerchantId: "",
    Description: "Choose One",
    MarkupRate: "",
    MarkupFee: "",
    MonthlyFee: "",
  });

  const classes = StyleCreator.makeStyles(formStyles.all);

  useEffect(() => {
    SetDealerId(dealerId);
  }, [dealerId]);

  useEffect(() => {
    SetMerchantId(merchantId);
    PricingModels["MerchantId"] = merchantId;
  }, [merchantId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    ClearValidationMessages();

    const { name, value } = event.target;

    // Regular expression to allow only numbers and a decimal point
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (name == "Description") {
      SetPricingModels((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      if (regex.test(value) || value === "") {
        SetPricingModels((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  function ClearValidationMessages() {
    setModelTypeMsg("");
    setMarkupRateMsg("");
    setMarkupFeeMsg("");
    setMonthlyFeeMsg("");
  }

  function GetLabels(value: any, key: any) {
    if (value == "Choose One") {
      if (key == "MarkupRate") {
        return "Markup rate (%)";
      } else if (key == "MarkupFee") {
        return "Markup fee/transaction ($)";
      } else if (key == "MonthlyFee") {
        return "Monthly fee ($)";
      }
    } else if (value == "Flat Rate") {
      if (key == "MarkupRate") {
        return "Markup rate %(1.50 - 9.99)";
      } else if (key == "MarkupFee") {
        return "Markup fee $(0.0 - 0.99)";
      } else if (key == "MonthlyFee") {
        return "Monthly fee $(0 - 99)";
      }
    } else if (value == "Interchange Plus") {
      if (key == "MarkupRate") {
        return "Markup rate %(0.15 - 1.50)";
      } else if (key == "MarkupFee") {
        return "Markup fee $(0.0 - 0.99)";
      } else if (key == "MonthlyFee") {
        return "Monthly fee $(0 - 99)";
      }
    }
  }

  async function CreatePricingModel(e: any) {
    ClearValidationMessages();

    setIsProcessing(true);
    if (!CheckData()) return;

    setIsProcessing(true);
    CreatePricingModels();

    setIsProcessing(false);
  }

  function CheckData(): any {
    var status: boolean = true;
    if (
      isEmpty(PricingModels.Description) ||
      PricingModels.Description == "Choose One"
    ) {
      setModelTypeMsg("Missing Pricing Model Type");
      setIsProcessing(false);
      status = false;
    }
    if (
      isEmpty(PricingModels.MarkupRate) ||
      (PricingModels.Description == "Flat Rate" &&
        (PricingModels.MarkupRate < 1.5 || PricingModels.MarkupRate > 9.99)) ||
      (PricingModels.Description == "Interchange Plus" &&
        (PricingModels.MarkupRate < 0.15 || PricingModels.MarkupRate > 1.5))
    ) {
      setMarkupRateMsg("Invalid input.");
      setIsProcessing(false);
      status = false;
    }

    if (
      isEmpty(PricingModels.MarkupFee) ||
      (PricingModels.Description == "Flat Rate" &&
        (PricingModels.MarkupFee < 0 || PricingModels.MarkupFee > 0.99)) ||
      (PricingModels.Description == "Interchange Plus" &&
        (PricingModels.MarkupFee < 0 || PricingModels.MarkupFee > 0.99))
    ) {
      setMarkupFeeMsg("Invalid input.");
      setIsProcessing(false);
      status = false;
    }

    if (
      isEmpty(PricingModels.MonthlyFee) ||
      PricingModels.MonthlyFee < 0 ||
      PricingModels.MonthlyFee > 99
    ) {
      setMonthlyFeeMsg("Invalid input.");
      setIsProcessing(false);
      status = false;
    }

    return status;
  }

  async function CreatePricingModels() {
    let obj: any = new Object();
    obj.MerchantId = PricingModels.MerchantId;
    obj.Description = PricingModels.Description;
    obj.MarkupRate = PricingModels.MarkupRate;
    obj.MarkupFee = PricingModels.MarkupFee;
    obj.MonthlyFee = PricingModels.MonthlyFee;

    let jsonString = JSON.stringify(obj);

    const res = await TargetPayApi("CreatePricingModels", jsonString);

    if (!isEmpty(res)) {
      toast.dismiss();
      if (res.isSuccessStatusCode) {
        onAddPriceModel({ ...obj, SplitConfigurationId: res.reasonPhrase });
        toast.success("Pricing Model created successfully.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeOnClick: true,
          style: { width: "340px" },
        });
      } else {
        toast.error(res.reasonPhrase, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeOnClick: true,
        });
      }
    }
  }

  return (
    <Drawer open={open} onClose={onClose} size="xs" enforceFocus={false}>
      <Box style={{ border: "0px solid red" }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ padding: "0px", paddingRight: "0px", marginTop: "75px" }}
        ></Box>
        <Box
          style={{
            padding: "5px",
            fontSize: "18px",
            color: "#3366ff",
            backgroundColor: "",
            borderBottom: "1px solid #EAEDED",
          }}
        >
          Create Pricing Model
        </Box>
        <Box
          style={{
            margin: "20px 5px 0px 15px",
            border: "0px solid red",
          }}
        >
          <Box style={{ fontSize: "small" }}>
            <Box style={formStyles.fieldSpacer}>
              <FormControl>
                <InputLabel
                  variant="standard"
                  id="demo-simple-select-label"
                  sx={{ fontSize: "large" }}
                >
                  Pricing Model Type *
                </InputLabel>
                <Select
                  variant="standard"
                  required
                  sx={{ fontSize: "small", width: "380px" }}
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="Description"
                  value={PricingModels.Description}
                  onChange={handleChange}
                >
                  {PricingModelType.map((row: any, index: number) => {
                    return (
                      <MenuItem
                        key={index}
                        sx={{ fontSize: "small" }}
                        value={row.Description}
                        id={row.Description}
                      >
                        {row.Description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            {ModelTypeMsg != "" && (
              <Box
                style={{
                  color: "red",
                }}
              >
                {ModelTypeMsg}
              </Box>
            )}
            <Box style={formStyles.fieldSpacer}>
              <TextField
                label={GetLabels(PricingModels.Description, "MarkupRate")}
                className={classes.root}
                variant="standard"
                fullWidth
                required
                id="outlined-required"
                name="MarkupRate"
                value={PricingModels.MarkupRate}
                inputProps={{
                  style: { fontSize: "small" },
                }}
                onChange={handleChange}
              />
            </Box>
            {MarkupRateMsg != "" && (
              <Box
                style={{
                  color: "red",
                }}
              >
                {MarkupRateMsg}
              </Box>
            )}
            <Box style={formStyles.fieldSpacer}>
              <TextField
                label={GetLabels(PricingModels.Description, "MarkupFee")}
                className={classes.root}
                variant="standard"
                fullWidth
                required
                id="outlined-required"
                name="MarkupFee"
                value={PricingModels.MarkupFee}
                inputProps={{
                  style: { fontSize: "small" },
                }}
                onChange={handleChange}
              />
            </Box>
            {MarkupFeeMsg != "" && (
              <Box
                style={{
                  color: "red",
                }}
              >
                {MarkupFeeMsg}
              </Box>
            )}
            <Box style={formStyles.fieldSpacer}>
              <TextField
                label={GetLabels(PricingModels.Description, "MonthlyFee")}
                className={classes.root}
                variant="standard"
                fullWidth
                required
                id="outlined-required"
                name="MonthlyFee"
                value={PricingModels.MonthlyFee}
                inputProps={{
                  style: { fontSize: "small" },
                }}
                onChange={handleChange}
              />
            </Box>
            {MonthlyFeeMsg != "" && (
              <Box
                style={{
                  color: "red",
                }}
              >
                {MonthlyFeeMsg}
              </Box>
            )}
            <Box
              style={{
                padding: "10px 10px 10px 0px",
                textAlign: "left",
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <td>
                      <Button
                        disabled={
                          PricingModels.Description == "Choose One" ||
                          isEmpty(PricingModels.MarkupRate) ||
                          isEmpty(PricingModels.MarkupFee) ||
                          isEmpty(PricingModels.MonthlyFee)
                        }
                        style={formStyles.fieldSpacer}
                        variant="contained"
                        onClick={(e) => CreatePricingModel(e)}
                      >
                        Create
                      </Button>
                    </td>
                    <td>
                      {isProcessing && (
                        <CircularProgress
                          size={25}
                          style={{
                            marginLeft: "20px",
                            marginTop: "20px",
                          }}
                          color="info"
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
