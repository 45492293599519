import { TableCell } from "@mui/material";
import { PaymentStatus } from "../../types/enums";

export default function PaymentStatusColumn({
  isRefunded,
  isCancelled,
  status,
  refundInitiated,
  handleClick,
  renderAsColumn = true,
}) {
  const statusText = isRefunded
    ? PaymentStatus.Refunded
    : refundInitiated
    ? PaymentStatus.PendingRefund
    : isCancelled
    ? PaymentStatus.Cancelled
    : status?.toLowerCase() == "failure" || status?.toLowerCase() == "failed"
    ? "Failed"
    : status?.toLowerCase() == "link sent"
    ? PaymentStatus.Pending
    : status
    ? PaymentStatus[status]
    : PaymentStatus.Pending;
  const statusColor =
    isRefunded || isCancelled || status == "Cancelled"
      ? "orange"
      : refundInitiated
      ? "black"
      : status?.toLowerCase() == "failure" || status?.toLowerCase() == "failed"
      ? "red"
      : status?.toLowerCase() == "success"
      ? "green"
      : "black";
  return renderAsColumn ? (
    <TableCell onClick={handleClick} style={{ color: statusColor }}>
      {statusText}
    </TableCell>
  ) : (
    <label style={{ color: statusColor }}>{statusText}</label>
  );
}
