import { RootState } from "../app/store";
import { useAppSelector } from "../app/hooks";
import { decodeToken } from "../helpers/helpers";
import { isEmpty } from "../utils/Util";

export const useCheckTLDomainUser = () => {
  const access_token = useAppSelector(
    (state: RootState) => state.auth.access_token
  );

  if (!access_token || access_token === undefined) {
    return false;
  } else {
    const decodedJwt = decodeToken(access_token);
    // Check if TL Domain User
    let dealerId: any = JSON.parse(decodedJwt.profile)["dealerId"];

    if (
      decodedJwt["email"] != undefined &&
      (isEmpty(dealerId) || dealerId == null || dealerId === 0)
    ) {
      return true;
    }
    return false;
  }
};
