import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TransactionSettings {
  showActionColumn: boolean
  shouldLoadPageSettings: boolean
}

const initialState: TransactionSettings = {
	showActionColumn: false,
	shouldLoadPageSettings: false
};

const transactionSettingSlice = createSlice({
  name: "pageSettings",
  initialState,
  reducers: {
    setTransactionSettings: (state, action: PayloadAction<any>) => {
		const actionColumnSetting = action.payload.find(item => item.settingName == "ShowActionColumn");

		if(actionColumnSetting){
      		state.showActionColumn = actionColumnSetting.settingValue.toLowerCase() == "true";
		}
    },

    shouldLoadPageSettings: (state, action: PayloadAction<boolean>) => {
		state.shouldLoadPageSettings = action.payload;
	  },  
	},
});

export const { setTransactionSettings, shouldLoadPageSettings } = transactionSettingSlice.actions;

export default transactionSettingSlice.reducer;
