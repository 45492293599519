import { Callback } from "../../utils/Util";
import { EverestApi, TargetPayApi } from "../../utils/UtilTarget";
import { isEmpty } from "../../utils/Util";
import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";

let paymentRequest: any;
let tokenRequest: any;
let responseStatus: any;
let amount = 100;
let lastSaveCard = false;

export const slice = createSlice({
  name: "payment",
  initialState: {
    error: "",
    session: null,
    orderRef: null,
    paymentDataStoreRes: null,
    config: {
      storePaymentMethod: true,
      paymentMethodsConfiguration: {
        ideal: {
          showImage: true,
        },
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          name: "Credit or debit card",
          amount: {
            value: 10000, // 100$ in minor units
            currency: "USD",
          },
        },
      },
      shopperReference: "Order Number 123",
      shopperInteraction: "Ecommerce",
      shopperLocale: "en_US",
      showPayButton: true,
      clientKey: "",
      environment: `${process.env.REACT_APP_ENV}` === "Prod" ? "live" : "test",
    },
  },
  reducers: {
    paymentSession: (state, action) => {
      state.error = "";
      state.session = null;
      state.orderRef = null;

      const [res, status] = action.payload;
      if (status >= 300) {
        state.error = res;
      } else {
        //[state.session, state.orderRef] = res;
        state.session = res;
        state.orderRef = res;
        state.config.shopperInteraction = res.shopperInteraction;
        state.config.shopperLocale = res.shopperLocale;
        state.config.shopperReference = res.reference;
        state.config.paymentMethodsConfiguration.card.amount.currency =
          res.amount.currency;
        state.config.paymentMethodsConfiguration.card.amount.value =
          res.amount.value;
        state.config.clientKey = res.adyenClientKey;
      }
    },
    clearPaymentSession: (state) => {
      state.error = "";
      state.session = null;
      state.orderRef = null;
    },
    paymentDataStore: (state, action) => {
      const [res, status] = action.payload;
      if (status >= 300) {
        state.error = res;
      } else {
        state.paymentDataStoreRes = res;
      }
    },
  },
});

export const { paymentSession, clearPaymentSession, paymentDataStore } =
  slice.actions;

export const initiateCheckout =
  (token, saveCard) => async (dispatch: Dispatch) => {
    if (
      token == tokenRequest &&
      responseStatus == 200 &&
      lastSaveCard == saveCard
    ) {
      dispatch(paymentSession([paymentRequest, responseStatus]));
      return;
    }

    let obj: any = new Object();
    obj.token = token;
    obj.saveCard = saveCard;
    let jsonString = JSON.stringify(obj);

    const res = await TargetPayApi("AdyenPaymentSession", jsonString);
    if (!isEmpty(res)) {
      paymentRequest = res;
      responseStatus = 200;
      lastSaveCard = saveCard;
      dispatch(paymentSession([res, res.status]));
    }
    tokenRequest = token;
  };



export const getPaymentDataStore = () => async (dispatch: any) => {
  const response = await fetch("/api/getPaymentDataStore");
  dispatch(paymentDataStore([await response.json(), response.status]));
};

export const cancelOrRefundPayment =
  (orderRef: any) => async (dispatch: any) => {
    await fetch(`/api/cancelOrRefundPayment?orderRef=${orderRef}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch(getPaymentDataStore());
  };

export default slice.reducer;
