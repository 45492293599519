import { makeStyles } from "@mui/styles";

export class StyleCreator{

    static makeStyles(styles:any): any {

        return makeStyles(theme => (
            styles
        ))();    
    }
}