import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DashboardState {
  isRemotePayment: boolean;
}

const initialState: DashboardState = {
  isRemotePayment: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setIsRemotePayment: (state, action: PayloadAction<boolean>) => {
      state.isRemotePayment = action.payload;
    },
  },
});

export const { setIsRemotePayment } = dashboardSlice.actions;

export default dashboardSlice.reducer;
