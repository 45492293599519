import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {  Box, CircularProgress, DialogContent, Icon, IconButton, Table, TableCell, TableRow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import SquareIcon from '@mui/icons-material/Square';
import CheckIcon from '@mui/icons-material/Check';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';

export interface OnBoardingStatusDetailsPopupPorps {
	openPopup: boolean;
	selectedDealer: any;
	onClose: (value: boolean) => void;
  }

export default function OnBoardingStatusDetails(props: OnBoardingStatusDetailsPopupPorps) {
	const { openPopup, selectedDealer, onClose } = props;

	const handleClose = () => {
		onClose(false);
	};
	
  return (
    <Dialog maxWidth="md" fullWidth={true} open={openPopup}>
      	<DialogTitle> {selectedDealer?.name} {" - "} {selectedDealer?.dmsDealerId}</DialogTitle>
      	<div style={{  position: 'absolute', right: 10, top: 10 }}>
			<IconButton aria-label="close" onClick={handleClose} 
				sx={{ color: (theme) => theme.palette.grey[500] }}>
				<CloseIcon />
			</IconButton>
		</div>
		<DialogContent dividers className="timeline-help">
			<Table size="small">                
				<TableRow>
					<TableCell sx={{ fontWeight: "bold", width: "350px" }}>
						Status</TableCell>
					<TableCell valign='middle'>
						<Box display="flex"alignItems="center">
							{selectedDealer?.status?.indexOf("progress") >-1 
							? <FlipCameraAndroidIcon sx={{fontSize: "20px", color: "orange"}} />
							: <CheckIcon sx={{fontSize: "20px", color: "green"}} /> }
							<span style={{ color: selectedDealer?.status?.indexOf("progress") >-1 ? "orange": "green", marginLeft: "8px" }}>
								{selectedDealer?.status}
							</span>
						</Box>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell sx={{ fontWeight: "bold", width: "350px" }}>
						<SquareIcon sx={{fontSize: "6px", marginRight: "8px"}} />Transfer Instrument Verified Date</TableCell>
					<TableCell>{selectedDealer?.transferInstrumentDateEnabled ? selectedDealer.transferInstrumentDateEnabled.toUSDateFormat(): "N/A"}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell sx={{ fontWeight: "bold", width: "350px" }}>
						<SquareIcon sx={{fontSize: "6px", marginRight: "8px"}} />Sweep Created Date</TableCell>
					<TableCell>{selectedDealer?.sweepDateCreated ? selectedDealer.sweepDateCreated.toUSDateFormat(): "N/A"}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell sx={{ fontWeight: "bold", width: "350px" }}>
						<SquareIcon sx={{fontSize: "6px", marginRight: "8px"}} />Onboarding Completion Date</TableCell>
					<TableCell>{selectedDealer?.onboardingCompletionDate ? selectedDealer.onboardingCompletionDate.toUSDateFormat(): "N/A"}</TableCell>
				</TableRow>
			</Table>
		</DialogContent>
    </Dialog>
  );
}