import * as React from 'react';
import { Timeline } from 'rsuite';
import MinusIcon  from '@rsuite/icons/Minus';
import '../../utils/string.extension';

export default function TransactionAuditTrailTimeline (props){
  const {timelines, showSummary} = props;

  return (
      <>
        {(!timelines || timelines.length == 0) && <label>No timeline found.</label>}          
        {(timelines && timelines.length > 0) &&
			<Timeline align='left'>
				{timelines.map((timeline, index) => (            
					<Timeline.Item key={index} dot={((index == 0)  && <MinusIcon className="timeline-start-icon" />)
										|| ((index == timelines.length -1) && <MinusIcon className="timeline-end-icon" />)}>
						<div> 
							<label>{timeline.content}</label><br/>        
							<p style={{display: "none"}}>{timeline.platformPaymentDesc}</p>                                  
							<p>{timeline.lastUpdated.toUSDateFormat()}</p>                    
							{timeline.failureReason && <p style={{color: "red", wordWrap: "break-word", width: "330px"}}>{timeline.failureReason}</p>}
						</div>
					</Timeline.Item>      
				))}
			</Timeline>
		}
      </>
  );
}
