import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Box, Container } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../app/hooks";
import {
  logout,
  setAccessToken,
  setCredentials,
} from "../../features/auth/authSlice";
import { setIsRemotePayment } from "../../features/dashboard/dashboardSlice";
import { GetRefreshToken } from "../../utils/UtilTarget";
import axios from "axios";

type JWTDeCode = {
  exp: number;
};

const Redirect = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("Please wait");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const access_token = urlParams.get("access_token");
    const refresh_token = urlParams.get("refresh_token");
    const isRemotePayment = urlParams.get("isRemotePayment");
    const isTLDomainUser = urlParams.get("isTLDomainUser");

    if (isRemotePayment) {
      dispatch(setIsRemotePayment(Boolean(isRemotePayment)));
    }

    // Validating Access_Token
    if (access_token) {
      const decodedJwt: JWTDeCode = jwt_decode(access_token);
      let currentDate = new Date();
      if (decodedJwt.exp * 1000 <= currentDate.getTime()) {
        // if token expired
        localStorage.clear();
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else {
        // Token is valid
        localStorage.clear();
        localStorage.setItem("Login_With", "TargetPay");
        dispatch(setAccessToken({ access_token }));
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("isTLDomainUser", isTLDomainUser);

        toast.success("Redirecting to the portal...", {
          toastId: 102,
          autoClose: 3000,
        });
        setTimeout(() => {
          navigate("/dashboard");
        }, 3000);
      }
    }
  }, []);

  return (
    <Container>
      <Box
        component="main"
        height="60vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <h2>{title}</h2>
        {title === "Please wait" ? (
          <>
            <h4>Redirecting to the Portal</h4>
            <CircularProgress size="2rem" sx={{ mt: "2rem" }} />
          </>
        ) : (
          <>
            <h4>Redirecting to the login page</h4>
            <CircularProgress size="2rem" sx={{ mt: "2rem" }} />
          </>
        )}
      </Box>
    </Container>
  );
};

export default Redirect;
