import * as React from "react";
import TransactionAuditTrail from "../Transaction/transaction.audittrail";
import {
  Card,
  CardHeader,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { convertToUSD, isEmpty } from "../../utils/Util";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router";
import PaymentMehtodType from "../../common/components/paymentmethod.type";
import ProgressLoader from "../../common/components/progress.loader";
import { DashboardDataLoader as DataLoader } from "./dashboard.dataloader";
import PaymentStatusColumn from "../../common/components/paymentstatus.column";
import { GetDealerPlatformFeeOption } from "../../utils/UtilTarget";

export default function DashboardRecentTransactions({ transactions }) {
  const drawerRef = React.useRef<any>();
  const [isFetching, setIsFetcing] = React.useState<any>(true);
  const [IsPlatformFeeEnabled, setIsPlatformFeeEnabled] =
    React.useState<any>(null);
  const navigate = useNavigate();

  const cardTitle = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item xs={8}>
          Recent Transactions{" "}
          <label style={{ fontSize: "14px" }}>(Last 10)</label>
        </Grid>
        <Grid item style={{ flexGrow: "1" }}></Grid>
        <Grid item xs={2}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            onClick={() => {
              navigate("/dashboard/transactions");
            }}
          >
            <label style={{ fontSize: "13px", cursor: "pointer" }}>
              View All{" "}
            </label>
            <ArrowForwardIcon style={{ fontSize: "20px", cursor: "pointer" }} />
          </Stack>
        </Grid>
      </Grid>
    );
  };

  React.useEffect(() => {
    setIsFetcing(true);

    let tempTimer = setTimeout(function () {
      setIsFetcing(false);
      clearTimeout(tempTimer);
    }, 400);
  }, [transactions]);

  React.useEffect(() => {
    setIsPlatformFeeEnabled(GetDealerPlatformFeeOption());
  }, []);

  function PaymentData() {
    if (transactions == null || transactions.Data == null) {
      return [];
    }
    return transactions.Data;
  }

  return (
    <Card
      style={{ marginTop: "10px", border: "1px solid #cfcfcf", height: "55vh" }}
      className="boxShadow"
    >
      <CardHeader title={cardTitle()}></CardHeader>
      <ProgressLoader isFetching={isFetching}></ProgressLoader>
      {!isFetching && (
        <TableContainer sx={{ height: "63vh" }}>
          <Table size={"small"} className="sticky-header">
            <TableHead>
              <TableRow>
                <TableCell>Order#</TableCell>
                <TableCell>Reference</TableCell>
                <TableCell>Location </TableCell>
                <TableCell>Customer </TableCell>
                <TableCell>Transaction Date</TableCell>
                <TableCell sx={{ textAlign: "right" }}>Amount</TableCell>
                {IsPlatformFeeEnabled && (
                  <>
                    <TableCell sx={{ textAlign: "right" }}>
                      Platform fee
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>Total</TableCell>
                  </>
                )}
                <TableCell>Status</TableCell>
                <TableCell style={{ display: 'none' }}>Card</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {PaymentData().length == 0 ? (
                <TableRow>
                  <TableCell colSpan={9}>No record found.</TableCell>
                </TableRow>
              ) : (
                PaymentData().map((row: any, index: any) => {
                  return (
                    <TableRow
                      onClick={() => {
                        drawerRef.current.openDrawer(row);
                      }}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        style={{
                          textDecorationLine: row.CancelOrRefund
                            ? "line-through"
                            : "",
                        }}
                      >
                        {row.InvoiceId}
                      </TableCell>
                      <TableCell>{row.PaymentId}</TableCell>
                      <TableCell>{row.DealerName}</TableCell> 
                      <TableCell>{row.CustomerName}</TableCell>
                      <TableCell>
                        {row.DateCreated.toUSDateFormat("M/DD/YYYY hh:mm A")}
                      </TableCell>
                      <TableCell align="right">
                        {convertToUSD(row.RequestedAmount)}
                      </TableCell>
                      {IsPlatformFeeEnabled && (
                        <>
                          <TableCell align="right">
                            {convertToUSD(row.PlatformFee)}
                          </TableCell>
                          <TableCell align="right">
                            {convertToUSD(row.TotalAmount)}
                          </TableCell>
                        </>
                      )}
                      <PaymentStatusColumn
                        isRefunded={row.IsRefunded}
                        isCancelled={row.IsCancelled}
                        status={row.Status}
                        refundInitiated={row.RefundInitiated}
                        renderAsColumn={true}
                        handleClick={() => {}}
                      ></PaymentStatusColumn>
                      <TableCell style={{ display: 'none' }}>
                        <PaymentMehtodType
                          type={row.PaymentMethod}
                        ></PaymentMehtodType>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TransactionAuditTrail ref={drawerRef}></TransactionAuditTrail>
    </Card>
  );
}
