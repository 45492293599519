import { DateRangePicker, DateRangePickerProps } from "rsuite";
import { format, startOfDay, endOfDay, addDays, subDays, startOfWeek, subMonths, subYears, startOfMonth, endOfMonth, startOfYear, endOfYear, isAfter} from "date-fns";
import { useEffect, useState } from "react";
import React from "react";
import { ContentPasteOffSharp } from "@mui/icons-material";

export const DateRangePickerCustom = ({dateRange, onDateRangePickerChange})=>{
    const today = new Date();
    const [currentDateRange, setValue] = React.useState(dateRange);
    const dateRanges: DateRangePickerProps["ranges"] = [
        {
            label: "Today",
            value: [startOfDay(today), endOfDay(today)],
            placement: "left",
        },
        {
            label: "Yesterday",
            value: [
                startOfDay(addDays(today, -1)),
                endOfDay(addDays(today, -1)),
            ],
            placement: "left",
        },
        {
            label: "This Week",
            value: [startOfWeek(today), endOfDay(today)],
            placement: "left",
        },
        {
            label: "Last 7 Days",
            value: [startOfDay(subDays(today, 6)), endOfDay(today)],
            placement: "left",
        },
        {
            label: `Current Month (${format(today, 'LLL')})`,
            value: [startOfMonth(today), endOfDay(today)],
            placement: "left",
        },
        {
            label: `Last Month (${format(subMonths(today, 1), 'LLL')})`,
            value: [startOfMonth(subMonths(today, 1)), endOfMonth(subMonths(today, 1))],
            placement: "left",
        },
        {
            label: `This Year (${format(today, 'yyyy')})`,
            value: [startOfYear(today), endOfDay(today)],
            placement: "left",
        },
        {
            label: `Last Year (${format(subYears(today, 1), 'yyyy')})`,
            value: [startOfYear(subYears(today, 1)), endOfYear(subYears(today, 1))],
            placement: "left",
        }];

    const handleDateValueChange = (value: [Date, Date] | null) => {         
        setValue(value);
        onDateRangePickerChange(value);      
    };	

    return (
        <DateRangePicker
            character=" - "
            ranges={dateRanges}
            size="lg"
			defaultValue = {dateRange}
            value = {currentDateRange}        
            format="M/dd/yyyy"
			placeholder="Date Range"
            placement="autoVertical"
			cleanable={false}
            showOneCalendar
            shouldDisableDate={(date) => isAfter(date, new Date())}
            style={{ marginRight: "5px" }}
			onChange={handleDateValueChange}
		/>           
    );
}