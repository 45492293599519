import { Box, Paper } from "@mui/material";
import { isEmpty } from "../../../utils/Util";
import React, { useEffect } from "react";
import { Drawer } from "rsuite";

export default function OnboardingErrors({
  open,
  onClose,
  verificationProblems,
}) {
  const [VerificationProblems, SetVerificationProblems] = React.useState<any>(
    []
  );

  useEffect(() => {
    // Parse the JSON string into a JavaScript object
    const parsedData = JSON.parse(verificationProblems);
    SetVerificationProblems(parsedData);
  }, [verificationProblems]);

  return (
    <Drawer open={open} onClose={onClose} size="xs">
      <Box style={{ border: "0px solid red" }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ padding: "0px", paddingRight: "0px", marginTop: "75px" }}
        ></Box>
        <Box
          style={{
            padding: "5px 5px 5px 22px",
            fontSize: "18px",
            color: "#3366ff",
            backgroundColor: "",
            borderBottom: "1px solid #EAEDED",
          }}
        >
          Verification Errors
        </Box>
        <Box
          style={{
            margin: "20px 5px 0px 15px",
            border: "0px solid red",
          }}
        >
          <Box style={{ fontSize: "small" }}>
            {!isEmpty(VerificationProblems) && (
              <Box style={{ display: "table" }}>
                <table
                  style={{
                    margin: "0px 10px",
                    padding: "10px",
                    fontSize: "small",
                    color: "red",
                  }}
                >
                  {VerificationProblems.map((row: any, index: any) => {
                    return (
                      <tbody key={index}>
                        {row.verificationErrors.map((row: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Box key={index} marginTop={"5px"}>
                                  {row.code}: {row.message}
                                </Box>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    );
                  })}
                </table>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
