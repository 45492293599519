import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    isLoaded: false
};

const appFeatureSlice = createSlice({
    name: 'appFeatures',
    initialState,
    reducers:{
        setFeatureLoading : (state, action: PayloadAction<any>) => {
            state.isLoaded = action.payload;
        }
    }
});

export const {setFeatureLoading} = appFeatureSlice.actions;
export default appFeatureSlice.reducer;