import {
    Box, Button, FormControl, InputLabel, MenuItem, Select,
} from "@mui/material";

export default function Report1() {
    return (
        <Box style={{minWidth:"800px"}}>
            <iframe src="https://ca-test.adyen.com/reports/download/Company/PerseusPayments/" style={{width:"100%", height:"99vh"}}></iframe>
        </Box>

    )

}