import SearchIcon from "@mui/icons-material/Search";
import { EverestApi, TargetPayApi } from "../../utils/UtilTarget";
import { useEffect, useState } from "react";
import { useGetUsersByDealerQuery } from "../../services/API/userApiSlice";
import { Helmet } from "react-helmet-async";

import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  Box,
  Container,
  IconButton,
  Paper,
  Stack,
  TableHead,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { isEmpty } from "../../utils/Util";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

//---------------------------- TablePaginationActions START--------------------------------//
function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
//---------------------------- TablePaginationActions END --------------------------------//
let mySearchTimer: any;
const User = () => {
  const dealerId = useAppSelector((state: RootState) => state.dealer.dealerId);
  // const [users, setUsers] = useState([] as any);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [users, setUsers] = React.useState<any>([]);
  const [paymentGatewayId, SetPaymentGatewayId] = React.useState<any>(-1);
  const [search, setSearch] = useState("");
  // Data Fetching
  // const { data, isFetching, isError } = useGetUsersByDealerQuery();

  // useEffect(() => {
  //   GetUserData();
  // }, [data]);

  useEffect(() => {
    init();
    // GetUserData();
  }, []);

  async function init() {
    let obj: any = new Object();
    let objBody: any = new Object();

    objBody.DealerId = dealerId;

    obj.query = "PerseusPay.DealerPaymentGateway";
    obj.queryBody = objBody;

    const res = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );
    if (!isEmpty(res)) {
      SetPaymentGatewayId(res[0].PaymentGatewayId);
    }
    GetUserData();
  }
  async function GetUserData() {
    let obj: any = new Object();
    let objBody: any = new Object();

    objBody.DealerId = dealerId;
    objBody.Search = search;

    obj.query = "PerseusPay.User";
    obj.queryBody = objBody;

    const resUser = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );

    setUsers(resUser);
  }

  // ----------------------- HANDLERS ---------------------------//

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (search == null) return;
    clearTimeout(mySearchTimer);
    mySearchTimer = setTimeout(function () {
      if (page > 0) {
        setPage(0);
      } else {
        GetUserData();
      }
    }, 700);
  }, [search]);

  return (
    <>
      <Helmet>
        <title> User | Target Pay </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h6" gutterBottom>
            Users
          </Typography>
          {/* {paymentGatewayId == 2 && (
            <Stack direction="row" alignItems="center" >
              <TextField
                // style={{ marginRight: "10px" }}
                id="outlined-search"
                placeholder="Search"
                // sx={{ width: "50ch" }}
                sx={{ width: "250px" }}
                size="small"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          )} */}

          {/* Search Filter */}
          {/* <Box display="flex" justifyContent="center" alignItems="center">
            <TextField
              id="outlined-start-adornment"
              placeholder="Search by Name"
              sx={{ m: 1, width: "30ch" }}
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box> */}
        </Stack>

        <TableContainer component={Paper}>
          <Table
            size={"small"}
            sx={{ minWidth: 500 }}
            aria-label="custom pagination table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>User Id</TableCell>
                <TableCell align="left" sx={{ fontWeight: "bold" }}>
                  Full Name
                </TableCell>
                {paymentGatewayId == 2 && (
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    User Name
                  </TableCell>
                )}
                <TableCell align="left" sx={{ fontWeight: "bold" }}>
                  Email
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: "bold" }}>
                  Phone Number
                </TableCell>
                {/* {paymentGatewayId == 2 && (
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    Terminal
                  </TableCell>

                )} */}
              </TableRow>
            </TableHead>

            <TableBody>
              {
                // isFetching ? (
                //   <TableRow>
                //     <TableCell />
                //     <TableCell />
                //     <TableCell align="left">
                //       <CircularProgress size="2rem" />
                //     </TableCell>
                //     <TableCell />
                //   </TableRow>
                // ) :
                <>
                  {users
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row: any) => (
                      <TableRow key={row.UserId}>
                        <TableCell component="th" scope="row">
                          {row.UserId}
                        </TableCell>

                        <TableCell align="left">{row.Name}</TableCell>
                        {paymentGatewayId == 2 && (
                          <TableCell align="left">{row.UserName}</TableCell>
                        )}

                        <TableCell align="left">{row.Email}</TableCell>

                        <TableCell align="left">{row.phoneNumber}</TableCell>
                        {/* {paymentGatewayId == 2 && (<TableCell align="left">{row.POIId}</TableCell>)} */}
                      </TableRow>
                    ))}
                </>
              }
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[15, 25, 50]}
                  count={users?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default User;
