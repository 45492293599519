import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export function DealerLocationFilter({dealerLocationsID, dealerLocations, onDealerLocationChange}){

	const [currentDealerLocationsID, setValue] = React.useState(dealerLocationsID);

	const handleLocationChange = (value) => {         
        setValue(value);
        onDealerLocationChange(value);      
    };

	return(
		<FormControl sx={{ mr: 1, minWidth: 150 }} size="small">
		<InputLabel id="filter-type-label">Locations</InputLabel>
		<Select                            
			labelId="demo-simple-select-label"
			id="demo-simple-select"
			label="Location"
			sx={{ fontSize: "normal", width: "330px", marginRight: "10px" }}
			value={currentDealerLocationsID}
			onChange={(e) => handleLocationChange(e.target.value)}>
				<MenuItem 
					sx={{ fontSize: "small" }}
					value={-1}>
					All
				</MenuItem>
				{dealerLocations.map((location: any, index) => {
					return (
						<MenuItem
							key={index}
							sx={{ fontSize: "small" }}
							value={location.DealerId}>
							{location.Name}
						</MenuItem>
					);
				})}
			</Select>                        
		</FormControl>
	);
}