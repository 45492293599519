import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableHead,
  Popover,
} from "@mui/material";
import axios from "axios";

let dollarUSLocale = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  useGrouping: true,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}); // $148,000

export default function StoreConfig() {
  const [storeInfo, setStoreInfo] = React.useState<any>();
  const [subData, setSubData] = React.useState<any>();
  const [showDetail, setShowDetail] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    GetStoreInfo();
  }, []);

  function GetStoreInfo() {
    let url = `${process.env.REACT_APP_BASE_URL}PerseusPay/GetAdyenAccountInfo`;
    // url = "https://localhost:44398/api/v1/PerseusPay/GetAdyenAccountInfo";
    // alert(url);
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .get(url, { headers: headers })
        .then(function (response) {
          setStoreInfo(response.data);
          // alert('done');
        })
        .catch(function (error) {
          alert(error);
        });
    } catch (err) {
      alert(err);
    }
  }

  function StoreTerminals() {
    try {
      return storeInfo.StoreTerminals.data;
    } catch (error) {
      return [];
    }
  }

  function ShowDetails(e: any, tag: any) {
    setSubData(tag);
    setShowDetail(true);
    setAnchorEl(e.currentTarget);
  }

  function GetBalance(data: any): any {
    try {
      if (data.length > 0) return Number(data[0].balance) / 100;
    } catch (error) {}
    return 0;
  }
  function PaymentMethods(data: any): any {
    try {
      let paymentMothods = data.data.length.toString() + ": ";
      for (let i = 0; i < data.data.length; i++) {
        paymentMothods += data.data[i].type + ", ";
      }
      return paymentMothods;
    } catch (error) {}
    return 0;
  }

  return (
    <Box>
      {storeInfo && (
        <Box>
          <Box style={{ margin: "10px" }}>
            <Box
              sx={{ fontSize: 18 }}
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
              onClick={(e) => ShowDetails(e, storeInfo.LegalEntity)}
            >
              Legal Entity
            </Box>
            <Box>Id: {storeInfo.LegalEntity.id}</Box>
            <Box>Type: {storeInfo.LegalEntity.type}</Box>
            <Box>
              Legal Name: {storeInfo.LegalEntity.organization.legalName}
            </Box>
            <Box>
              Address:{" "}
              {storeInfo.LegalEntity.organization.registeredAddress.street},{" "}
              {storeInfo.LegalEntity.organization.registeredAddress.city},{" "}
              {
                storeInfo.LegalEntity.organization.registeredAddress
                  .stateOrProvince
              }
              ,{" "}
              {storeInfo.LegalEntity.organization.registeredAddress.postalCode},{" "}
              {storeInfo.LegalEntity.organization.registeredAddress.country}
            </Box>
          </Box>

          <Box style={{ margin: "10px" }}>
            <Box
              sx={{ fontSize: 18 }}
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
              onClick={(e) => ShowDetails(e, storeInfo.AccountHolder)}
            >
              Account Holder
            </Box>
            <Box>Id: {storeInfo.AccountHolder.id}</Box>
            <Box>
              Balance Platform: {storeInfo.AccountHolder.balancePlatform}
            </Box>
            <Box>
              Description/Reference: {storeInfo.AccountHolder.description} (
              {storeInfo.AccountHolder.reference})
            </Box>
            <Box>LegalEntityId: {storeInfo.AccountHolder.legalEntityId}</Box>
            {/* <Box>Reference: {storeInfo.AccountHolder.reference}</Box> */}
          </Box>
          <Box style={{ margin: "10px" }}>
            <Box
              sx={{ fontSize: 18 }}
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
              onClick={(e) => ShowDetails(e, storeInfo.BalanceAccount)}
            >
              Balance Account
            </Box>
            <Box>Id: {storeInfo.BalanceAccount.id}</Box>
            <Box>Description: {storeInfo.BalanceAccount.description}</Box>
            <Box>TimeZone: {storeInfo.BalanceAccount.timeZone}</Box>
            {/* <Box>Currency: {storeInfo.BalanceAccount.defaultCurrencyCode}</Box> */}
            <Box>
              Balance:{" "}
              {dollarUSLocale.format(
                GetBalance(storeInfo.BalanceAccount.balances)
              )}{" "}
              ({storeInfo.BalanceAccount.defaultCurrencyCode})
            </Box>
          </Box>
          <Box style={{ margin: "10px" }}>
            <Box
              sx={{ fontSize: 18 }}
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
              onClick={(e) => ShowDetails(e, storeInfo.TransferInstruments)}
            >
              Transfer Instruments
            </Box>
            {/* <Box>Id: {storeInfo.TransferInstruments.id}</Box> */}
            <Box>
              Type: {storeInfo.TransferInstruments.type} (
              {
                storeInfo.TransferInstruments.bankAccount.accountIdentification
                  .type
              }
              )
            </Box>
            {/* <Box>BankAccount: {storeInfo.TransferInstruments.bankAccount.accountIdentification.type}</Box> */}
            <Box>
              AccountNumber:{" "}
              {
                storeInfo.TransferInstruments.bankAccount.accountIdentification
                  .accountNumber
              }
            </Box>
            <Box>
              AccountType:{" "}
              {
                storeInfo.TransferInstruments.bankAccount.accountIdentification
                  .accountType
              }
            </Box>
            <Box>
              RoutingNumber:{" "}
              {
                storeInfo.TransferInstruments.bankAccount.accountIdentification
                  .routingNumber
              }
            </Box>
            {/* <Box>TimeZone: {storeInfo.TransferInstruments.timeZone}</Box>
                        <Box>Currency: {storeInfo.TransferInstruments.defaultCurrencyCode}</Box> */}
          </Box>
          <Box style={{ margin: "10px" }}>
            <Box
              sx={{ fontSize: 18 }}
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
              onClick={(e) => ShowDetails(e, storeInfo.PaymentMethods)}
            >
              Payment Methods
            </Box>
            <Box>{PaymentMethods(storeInfo.PaymentMethods)}</Box>
          </Box>

          <Box style={{ margin: "10px" }}>
            <Box
              sx={{ fontSize: 18 }}
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
              onClick={(e) => ShowDetails(e, storeInfo.StoreTerminals)}
            >
              Store Terminals
            </Box>
            {
              <Paper>
                <TableContainer>
                  <Table
                    // sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={"small"}
                  >
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#F2F3F4" }}>
                        <TableCell>Terminal Id</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Last Activity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {StoreTerminals().map((row: any) => (
                        <TableRow>
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.assignment.status}</TableCell>
                          {/* <TableCell>{row.lastActivityAt}</TableCell> */}
                          <TableCell>
                            {moment
                              .utc(row.lastActivityAt)
                              .local()
                              .format("M/D/YYYY h:mm A")}
                          </TableCell>
                          {/* <TableCell>{moment.utc(row.lastActivityAt).local().format('M/D/YYYY h:mm A')}</TableCell> */}
                          {/* <Box>{row.id} ({row.assignment.status})</Box> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            }
          </Box>
        </Box>
      )}
      <Popover
        open={showDetail}
        // open={true}
        anchorEl={anchorEl}
        // onClose={handleCloseOrderDetail}
        onClose={(e) => setShowDetail(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box>
          <div style={{ padding: "5px 10px" }}>
            {subData && (
              <Box>
                <pre>{JSON.stringify(subData, null, 2)}</pre>
              </Box>
            )}
          </div>
        </Box>
      </Popover>
    </Box>
  );
}
